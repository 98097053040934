// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {BuyLicenseAction} from '@store/license/types';
import {LoadersId} from '@type/loaders';
import {ErrorMessage} from '@type/error';

// Services
import * as services from '@services/license';

// Actions
import errorHandler from '@store/errorHandler';
import {setIsLoading} from '@store/ui/loaders/actions';
import {closeModal} from '@store/ui/modal/actions';

function * buyLicenseProcess({payload: {collectionId, description}}: BuyLicenseAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_SENDING_COLLECTION_INFO, true));

    const response: boolean = yield call(services.buyLicense, collectionId, description);
    console.log('response buyLicense', response);
    if (response) {
      console.log('entering with true');
      yield put(setIsLoading(LoadersId.IS_SENDING_COLLECTION_INFO, false));
      yield put(closeModal());
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_SENDING_COLLECTION_INFO, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default buyLicenseProcess;
