// Dependencies
import React, {useState, useCallback} from 'react';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
// import TwitterConnectAction from '../TwitterConnectAction';

// StyleSheet
import styles from './TwitterPostAction.module.scss';

// Types
import {TwitterPostActionProps} from './types';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

function TwitterPostAction({
  hashtag,
  onAction,
  onCheck
}: TwitterPostActionProps): React.ReactElement {
  const {isLoading: isLoadingCompletingQuest} = useLoader(LoadersId.IS_COMPLETING_QUEST);
  const [clickButton, setClickButton] = useState<boolean>(false);

  const handleClickCheckAction = useCallback(() => {
    setClickButton(true);
    onAction();
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Typography variant={'text'} size={'xs'}>Post a message including the hashtag:</Typography>
          <Typography variant={'text'} size={'xs'}>{hashtag}</Typography>
        </div>
        <div className={styles.action}>
          <Button onClick={handleClickCheckAction}>Post</Button>
        </div>
      </div>

      <div className={styles.body}>
        <Typography variant={'text'} textAlign={'center'} size={'md'} weight={'regular'}>After completion, click this button to check and complete the quest.</Typography>
        <Button disabled={!clickButton} onClick={onCheck} isLoading={isLoadingCompletingQuest}>Claim as completed</Button>
      </div>
    </div>
  );
}

export default TwitterPostAction;
