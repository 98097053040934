// Dependencies
import React, {useCallback, useMemo, useState} from 'react';
import {FormikProps, useFormik} from 'formik';
import classNames from 'clsx';

// StyleSheet
import styles from './CreateOrEditRewardForm.module.scss';

// Components
import Button from '@components/Button';
import TextInput from '@components/TextInput';
import Selector from '@components/Selector';
import BannerUpload from '@components/BannerUpload';
import AvatarUpload from '@components/AvatarUpload';
import DateInput from '@components/DateInput';
import Typography from '@components/Typography';
import RadioInput from '@components/RadioInput';
import ImageUpload from '@components/ImageUpload';
import QuillTextInput from '@components/QuillTextInput';

// Types
import {FormValues, NewRewardFormProps} from './types';

// Validation
import {NewRewardStep1Validation, NewRewardStep2Validation} from './validations';

// Hooks
import useForm from '@hooks/useForm';
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';

// Config
import {rewardContentTypes} from '@config/index';

import language_es from 'src/locales/es/forms/createOrEditRewardForm.json';
import language_en from 'src/locales/en/forms/createOrEditRewardForm.json';

const initialDefaultValues: FormValues = {
  rewardPhoto: undefined,
  rewardBanner: undefined,
  rewardTitle: '',
  rewardUrl: '',
  rewardDescription: '',
  rewardType: {
    label: 'Gift',
    value: 2
  },
  rewardContentType: undefined,
  rewardContent: '',
  rewardDate: '',
  rewardUnique: false,
  claimingTitle: '',
  claimingInstructions: ''
};

export type {FormValues};

export default function CreateOrEditRewardForm({initialValues = initialDefaultValues, isLoading, onSubmit, onCancel}: NewRewardFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {user} = useAuth();
  const {isMobile} = useResponsive();
  const [step, setStep] = useState(0);
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues,
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: step === 0 ? NewRewardStep1Validation : NewRewardStep2Validation,
    onSubmit
  });

  if (initialValues.rewardPhoto === undefined) initialValues.rewardPhoto = user?.creator?.avatar;

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  /**
   * Validates the form using Formik's validateForm method.
   * If no errors are detected, the function increments the step
   * to proceed to the next step.
   */
  const handleNextStep = () => {
    void formik.validateForm().then(errors => {
      if (Object.keys(errors).length === 0) {
        setStep(step + 1);
      }
    });
  };

  /**
   * Callback function to handle dropping photos into a field.
   *
   * @function
   * @name handleDropPhoto
   * @param {string} field - The name of the field where the photo is dropped.
   * @param {File[]} photos - An array of File objects representing the dropped photos.
   * @returns {void}
   */
  const handleDropPhoto = useCallback((field: keyof FormValues, photos: File[]) => {
    if (photos.length === 1) {
      const file = photos[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        const photo = Object.assign(photos[0], {
          preview: URL.createObjectURL(file)
        });
        handleChangeField(field, photo);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const renderContentType = useMemo(() => {
    return (
      <>
        <div className={classNames([styles.inline, styles.rewardRow, styles.withoutCenter])}>
          <div>
            <Selector
              placeholder={language.contentTypeSelector}
              options={rewardContentTypes.map((reward) => ({
                value: reward.value,
                label: language[reward.label as keyof typeof language]
              }))}
              value={formik.values.rewardContentType}
              disabled={formik.values.rewardUnique}
              onChange={(item) => {
                handleChangeField('rewardContent', '');
                handleChangeField('rewardContentType', item);
              }}
            />
          </div>
          {formik.values.rewardContentType && (
            formik.values.rewardContentType?.value !== 3 ? (
              <TextInput
                name={'rewardContent'}
                placeholder={formik.values.rewardContentType.value === 1 ? language.rewardCodePlaceholder : language.rewardURLPlaceholder}
                value={formik.values.rewardContent ?? ''}
                disabled={formik.values.rewardUnique}
                error={getErrorFromField('rewardContent')}
                onChangeText={(rewardContent) => handleChangeField('rewardContent', rewardContent)}
              />
            ) : (
              <ImageUpload
                onDrop={(photos) => handleDropPhoto('rewardContent', photos)}
                photo={formik.values.rewardContent}
              />
            )
          )}
        </div>
      </>
    );
  }, [formik.values, rewardContentTypes]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <BannerUpload
        onDrop={(photos) =>
          handleDropPhoto('rewardBanner', photos)
        }
        photo={formik.values.rewardBanner}
      />
      <div className={styles.name}>
        <div className={styles.avatar}>
          <AvatarUpload
            onDrop={(photos) => handleDropPhoto('rewardPhoto', photos)}
            photo={formik.values.rewardPhoto}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h2'} weight={'semiBold'}>{formik.values.rewardTitle}</Typography>
          </div>
        </div>
      </div>

      <div className={classNames([styles.container, styles.body])}>
        {step === 0 && (
          <div className={styles.grid}>
            <div className={classNames([styles.inline, styles.only])}>
              <TextInput
                name={'title'}
                placeholder={formik.values.rewardType?.value === 1 ? language.eventTitleLabel : language.giftTitleLabel}
                value={formik.values.rewardTitle}
                error={getErrorFromField('rewardTitle')}
                onChangeText={(title) => handleChangeField('rewardTitle', title)}
              />
            </div>

            <div className={classNames([styles.inline, styles.only])}>
              <DateInput
                type={'datetime-local'}
                label={formik.values.rewardType?.value === 1 ? language.eventDateLabel : language.giftReleaseDateLabel}
                placeholder={''}
                value={formik.values.rewardDate}
                onChange={(date) => handleChangeField('rewardDate', date)}
                error={getErrorFromField('rewardDate')}
              />
            </div>

            <QuillTextInput
              className={styles.description}
              placeholder={formik.values.rewardType?.value === 1 ? language.eventDescriptionPlaceholder : language.giftDescriptionPlaceholder}
              theme='snow'
              value={formik.values.rewardDescription}
              onChange={(value) => handleChangeField('rewardDescription', value === '<p><br></p>' ? '' : value)}
              error={getErrorFromField('rewardDescription')}
            />

            <div className={classNames([styles.inline, styles.only])}>
              <TextInput
                name={'title'}
                placeholder={formik.values.rewardType?.value === 1 ? language.eventTitleForHoldersLabel : language.giftTitleForHoldersLabel}
                value={formik.values.claimingTitle}
                error={getErrorFromField('claimingTitle')}
                onChangeText={(title) => handleChangeField('claimingTitle', title)}
              />
            </div>

            <QuillTextInput
              className={styles.description}
              placeholder={formik.values.rewardType?.value === 1 ? language.eventDescriptionForHoldersPlaceholder : language.giftDescriptionForHoldersPlaceholder}
              theme='snow'
              value={formik.values.claimingInstructions}
              onChange={(value) => handleChangeField('claimingInstructions', value === '<p><br></p>' ? '' : value)}
              error={getErrorFromField('claimingInstructions')}
            />

            <div className={classNames([styles.inline, styles.only])}>
              <TextInput
                name={'url'}
                placeholder={formik.values.rewardType?.value === 1 ? language.eventURLPlaceholder : language.giftURLPlaceholder}
                value={formik.values.rewardUrl}
                error={getErrorFromField('rewardUrl')}
                onChangeText={(url) => handleChangeField('rewardUrl', url)}
              />
            </div>
          </div>
        )}

        {step === 1 && (
          <div className={styles.grid}>
            <div className={classNames([styles.inline, styles.withoutCenter])}>
              <RadioInput
                label={language.sameForAllUsersLabel}
                description={language.sameForAllUsersDescription}
                defaultChecked={formik.values.rewardUnique}
                checked={!formik.values.rewardUnique}
                onChange={() => {
                  handleChangeField('rewardUnique', false);
                  handleChangeField('rewardContentType', initialValues?.rewardContentType);
                  handleChangeField('rewardContent', initialValues?.rewardContent ?? '');
                }}
              />
              {(isMobile && !formik.values.rewardUnique) && renderContentType}
              <RadioInput
                label={language.uniqueForEachUserLabel}
                description={language.uniqueForEachUserDescription}
                defaultChecked={formik.values.rewardUnique}
                checked={formik.values.rewardUnique}
                onChange={() => {
                  handleChangeField('rewardUnique', true);
                  handleChangeField('rewardContentType', 1);
                  handleChangeField('rewardContent', null);
                }}
              />
            </div>
            {(!isMobile && !formik.values.rewardUnique) && renderContentType}
          </div>
        )}
        <div className={styles.actions}>
          <Typography variant={'text'} weight={'medium'} size={'sm'}>{language.stepIndicator.replace('{{currentStep}}', (step + 1).toString())}</Typography>
          <div className={styles.actions}>
            <div>
              <Button
                variant="outline"
                color="primary"
                type={'button'}
                onClick={() => (step === 0 ? onCancel() : setStep(0))}
                disabled={isLoading}
              >
                {step === 0 ? language.cancelButton : language.backButton}
              </Button>
            </div>
            <div>
              <Button
                variant="solid"
                color="primary"
                type={'button'}
                onClick={() => (step === 0 ? handleNextStep() : formik.handleSubmit())}
                disabled={!formik.isValid || isLoading}
                isLoading={isLoading && step === 1}
              >
                {step === 0 ? language.nextButton : language.saveButton}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
