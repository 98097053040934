// Dependencies
import {useDispatch, useSelector} from 'react-redux';

// Actions
import {fetchGiftList, fetchGiftById, fetchGiftListByCollectionId, createGiftAction, editGiftAction, deleteGiftAction, publishGiftAction} from '@store/gift/actions';

// Types
import {
  FetchGiftListAction,
  FetchGiftByIdAction,
  FetchGiftListByCollectionIdAction,
  CreateGiftAction,
  EditGiftAction,
  DeleteGiftAction,
  PublishGiftAction,
  CreateGiftActionProps,
  EditGiftActionProps
} from '@store/gift/types';
import {IGift} from '@type/gift';
import {RootState} from '@store/reducer';

// Selectors
import {selectGiftList, selectGiftListByCollectionId, selectGiftById, selectCollectionGiftById} from '@store/gift/selector';

interface IUseGifts {
  giftList?: IGift[];
  giftListByCollectionId?: IGift[];
  gift: IGift,
  fetchGiftList: (onlyPublished: Boolean) => FetchGiftListAction;
  fetchGiftById: (giftId: number) => FetchGiftByIdAction;
  createGift: (collectionId: number, gift: CreateGiftActionProps) => CreateGiftAction;
  editGift: (giftId: number, data: EditGiftActionProps['data']) => EditGiftAction;
  deleteGift: (giftId: number) => DeleteGiftAction;
  publishGift: (giftId: number) => PublishGiftAction;
  fetchGiftListByCollectionId: (collectionId: number, onlyPublished: boolean) => FetchGiftListByCollectionIdAction;
}

function useGifts(id?: number, fromCollection: Boolean = false): IUseGifts {
  const dispatch = useDispatch();
  const giftList = useSelector(selectGiftList);
  const giftListByCollectionId = useSelector((state: RootState) => selectGiftListByCollectionId(state));
  const gift = !fromCollection ? useSelector((state: RootState) => selectGiftById(state, id)) : useSelector((state: RootState) => selectCollectionGiftById(state, id));

  return {
    giftList,
    giftListByCollectionId,
    gift,
    fetchGiftList: (onlyPublished: Boolean) => dispatch(fetchGiftList(onlyPublished)),
    fetchGiftById: (giftId: number) => dispatch(fetchGiftById(giftId)),
    createGift: (collectionId: number, gift: CreateGiftActionProps) => dispatch(createGiftAction(collectionId, gift)),
    editGift: (giftId: number, data: EditGiftActionProps['data']) => dispatch(editGiftAction(giftId, data)),
    deleteGift: (giftId: number) => dispatch(deleteGiftAction(giftId)),
    publishGift: (giftId: number) => dispatch(publishGiftAction(giftId)),
    fetchGiftListByCollectionId: (collectionId: number, onlyPublished: Boolean) => dispatch(fetchGiftListByCollectionId(collectionId, onlyPublished))
  };
}

export default useGifts;
