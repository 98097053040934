import {IUserCreator} from '@type/user';

export enum QuestTypeEnum {
  Twitter = 1,
  Instagram = 2,
  Discord = 3,
  TikTok = 4,
  YouTube = 5,
  Twitch = 6
}

export enum QuestActionTypeEnum {
  TwitterFollow = 1,
  TwitterLike = 2,
  TwitterReply = 3,
  TwitterRetweet = 4,
  TwitterTweet = 5,
  TwitterJoinSpaces = 6,
  InstagramFollow = 7,
  JoinDiscord = 8,
  TikTokFollow = 9,
  YouTubeSubscribe = 10,
  TwitchFollow = 11
}

export interface IQuestType {
  id: number;
  name?: string;
  icon?: string;
}

export interface IQuestActionType {
  id: number;
  name?: string;
  title?: string;
  description?: string;
  questType?: IQuestType
}

export interface IQuest {
  id: number;
  title: string;
  questActionType: IQuestActionType;
  questActionTypeId?: number;
  description: string;
  handler: string;
  extraHandler?: string;
  xp: number;
  collectionId?: number;
  collection?: {
    id: number;
    creator: IUserCreator
  }
}

export interface IUserQuest extends IQuest {
  quest: IQuest;
  status: boolean;
}

export interface ITwitterAuthData {
  codeVerifier: string;
  state: string;
  url: string;
}

export interface IDiscordAuthData {
  code: string;
  state: string;
}
