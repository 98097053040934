// Dependencies
import Web3 from 'web3';
import {SagaIterator} from 'redux-saga';
import {call, delay, put} from 'redux-saga/effects';

// Services
import * as nftServices from '@services/nft';

// Types
import {ClaimNFTAction} from '@store/nft/types';
import {ClaimNFTResponse} from '@services/nft/types';
import {NFTState} from '@type/nft';

// Actions
import {setNFTData, setNFTState, decreaseNFTsPendingToClaim} from '@store/nft/actions';

// Utils
import {getContractAddressByChainId} from '@utils/web3';

// Abis
import nftClaimer from '@abis/GivitNFTMinter.json';
import forwarderAbi from '@abis/GivitNFTForwarder.json';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function buildForwarderBaseStruct
 * @description Constructs the EIP-712 compliant base structure for the forwarder.
 *
 * @param {string} forwarderAddress - The Ethereum address of the forwarder contract.
 *
 * @returns {object} - The EIP-712 compliant base structure for the forwarder.
 */
const buildForwarderBaseStruct = (forwarderAddress: string): object => {
  const ForwardRequest = [
    {name: 'from', type: 'address'},
    {name: 'to', type: 'address'},
    {name: 'value', type: 'uint256'},
    {name: 'gas', type: 'uint256'},
    {name: 'nonce', type: 'uint256'},
    {name: 'deadline', type: 'uint48'},
    {name: 'data', type: 'bytes'}
  ];

  const EIP712Domain = [
    {name: 'name', type: 'string'},
    {name: 'version', type: 'string'},
    {name: 'chainId', type: 'uint256'},
    {name: 'verifyingContract', type: 'address'}
  ];

  const domain = {
    name: 'GivitNFTForwarder',
    version: '1.0.0',
    chainId: parseInt(window.ethereum.networkVersion),
    verifyingContract: forwarderAddress
  };

  const types = {
    EIP712Domain,
    ForwardRequest
  };

  const msgData = {
    types,
    domain,
    primaryType: 'ForwardRequest'
  };

  return msgData;
};

/**
 * @function claimNFTProcess
 * @generator
 * @description This saga handles the process of claiming an NFT. It interacts with the blockchain and the backend to claim the NFT.
 * It handles the call to the API for claiming the NFT, interacting with the Ethereum blockchain, and managing the NFT state.
 *
 * @param {ClaimNFTAction} action - The dispatched action with the NFT to claim.
 *
 * @yields {Object} - A series of effects to handle the NFT claiming process.
 *
 * @returns {void} - Returns nothing.
 */
function * claimNFTProcess({payload: {nft}}: ClaimNFTAction): SagaIterator<void> {
  try {
    if (nft.state === NFTState.IS_WAITING_CONFIRMATION) {
      try {
        const nftStateProcess = yield call(nftServices.getNFTTransactionState, nft.nftItemId);

        if (nftStateProcess?.status === true) {
          yield put(setNFTData(nft.nftId, {
            tx: nft.tx,
            status: true,
            state: NFTState.RECENTLY_CLAIMED
          }));
        } else {
          throw new Error(`${nft.tx} is not validated yet`);
        }
      } catch (error) {
        //  yield put(setNFTState(nft.userNftItemId, NFTListState.CLAIMED));
      }
    } else {
      yield delay(1000); // In Metamask mobile there's a delay changing network. Waiting 1 second solve it

      yield put(setNFTState(nft.userNftItemId, NFTState.PROCESSING));
      const response: ClaimNFTResponse = yield call(nftServices.claimNFT, nft.nftItemId);
      const contractAddress = getContractAddressByChainId(nft.chainId);

      if (response) {
        const web3 = new Web3(window.ethereum);
        const claimerContract = yield call(() => new web3.eth.Contract((nftClaimer as any), contractAddress.claimer));

        const {message, signature} = response;

        const len = (message.length / 2) - 1;
        const messageLen = web3.utils.asciiToHex(len.toString());

        const txAbi = yield call(() => claimerContract.methods.claimNFT(message, messageLen, signature));
        const from = yield call(web3.utils.toChecksumAddress, window.ethereum.selectedAddress);
        const forwarderContract = yield call(() => new web3.eth.Contract((forwarderAbi as any), contractAddress.forwarder));
        const nonce = yield call(() => forwarderContract.methods.nonces(from).call({from}));
        const gas = yield call(() => txAbi.estimateGas({from}));

        const request = yield call(() => ({
          from,
          to: contractAddress.claimer,
          value: 0,
          gas: Math.round(Number(gas) * 2),
          data: txAbi.encodeABI(),
          deadline: new Date().getTime() + 60
        }));

        const requestData = yield call(() => ({
          ...request,
          nonce: Number(nonce)
        }));

        const msgParams = yield call(() => ({
          message: requestData,
          ...buildForwarderBaseStruct(contractAddress.forwarder)
        }));

        const method = 'eth_signTypedData_v4';
        const params = yield call(() => [from, JSON.stringify(msgParams)]);

        const signedMessage = yield call(async() => await new Promise((resolve, reject) => {
          (web3.currentProvider as any)?.send({
            method,
            params,
            from
          }, (e: any, {result}: any) => {
            if (e) { return reject(e); }
            resolve(result);
          });
        }));

        const tx: string = yield call(nftServices.claimNFTAirDrop, {
          id: nft.nftItemId,
          requestData: {...requestData, signature: signedMessage},
          message: msgParams,
          signature: signedMessage
        });

        if (tx) {
          yield put(setNFTState(nft.userNftItemId, NFTState.IS_WAITING_TRANSACTION));
          while (true) {
            try {
              const nftStateProcess = yield call(nftServices.getNFTTransactionState, nft.nftItemId);

              yield delay(5000);

              if (nftStateProcess?.status === true) {
                yield put(setNFTData(nft.userNftItemId, {
                  tx,
                  status: true,
                  state: NFTState.RECENTLY_CLAIMED
                }));

                yield put(decreaseNFTsPendingToClaim());

                break;
              } else {
                throw new Error(`${tx} is not validated yet`);
              }
            } catch (error) {
              //  yield put(setNFTState(nft.userNftItemId, NFTListState.CLAIMED));
              console.error(error);
            }
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
    yield put(setNFTState(nft.userNftItemId, NFTState.READY_TO_CLAIM));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default claimNFTProcess;
