// Types
import {ModalsId} from '@type/modals';
import {INFT} from '@type/nft';

export enum ModalActions {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface ModalData {}

export interface IModalState {
  id: ModalsId | null;
  data?: ModalData;
}

export interface OpenModalAction<T extends ModalData> {
  type: ModalActions.OPEN_MODAL;
  payload: {
    id: ModalsId | null;
    data: T;
  }
}

export interface CloseModalAction {
  type: ModalActions.CLOSE_MODAL;
}

/**
 * @interface VerifyEmailSignup
 * @description Data of VerifyEmailSignup Modal.
 */
export interface VerifyEmailSignup extends ModalData {
  email: string;
}

/**
 * @interface SendNFT
 * @description Data of SendNFT Modal.
 */
export interface SendNFT extends ModalData {
  nft: INFT;
  email?: string;
  isComplete?: boolean;
}

/**
 * @interface EditNFTDetails
 * @description Data of EditNFTDetails Modal.
 */
export interface EditNFTDetails extends ModalData {
  name: string;
  description: string;
  numberOfCopies: number;
  freeCopies: number;
}

/**
 * @interface ConfirmMessage
 * @description Data of ConfirmMessage Modal.
 */
export interface ConfirmMessage extends ModalData {
  title: string;
  description: string | string[];
  action: () => void;
}

/**
 * @interface PublishingCollection
 * @description Data of ConfirmMessage Modal.
 */
export interface PublishingCollection extends ModalData {
  type: 'loading' | 'completed';
  action: () => void;
}

export interface ClaimingAirdropCollectionNFT extends ModalData {
  type: 'loading' | 'completed';
  nftUrl: string;
  action: () => void;
}

/**
 * @interface CreateGiftAction
 * @description Modal to create a new gift for a collection
 */
export interface CreateGiftAction extends ModalData {
  collectionId: number;
}

/**
 * @interface CreateEventAction
 * @description Modal to create a new event for a collection
 */
export interface CreateEventAction extends ModalData {
  collectionId: number;
}

export type ModalActionsData =
  SendNFT |
  EditNFTDetails |
  ConfirmMessage |
  PublishingCollection |
  VerifyEmailSignup |
  CreateGiftAction |
  CreateEventAction |
  ClaimingAirdropCollectionNFT;

export type ModalActionsTypes = OpenModalAction<ModalData> | CloseModalAction;
