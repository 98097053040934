import * as Yup from 'yup';

const CreateNFTValidation = Yup.object().shape({
  image: Yup.mixed().required('The multimedia is required to create the NFT'),
  name: Yup.string().required('The title is required'),
  copies: Yup.number().required('The copies are required').min(1, 'Copies cannot be less than 1'),
  freeCopies: Yup.number()
    .required('The airdrop is required (default 0)')
    .min(0, 'The airdrop copies cannot be less than 0')
    .test(
      'freeCopies',
      'Cannot be more than total copies',
      function(value) {
        const {copies} = this.parent;
        if (value === undefined) {
          return this.createError({
            path: this.path,
            message: 'Fill this field'
          });
        }
        return value <= copies;
      }
    ),
  questCopies: Yup.number()
    .required('The airdrop is required (default 0)')
    .min(0, 'The airdrop copies cannot be less than 0')
    .test(
      'freeCopies',
      'Cannot be more than total copies',
      function(value) {
        const {copies} = this.parent;
        if (value === undefined) {
          return this.createError({
            path: this.path,
            message: 'Fill this field'
          });
        }
        return value <= copies;
      }
    )
});

export default CreateNFTValidation;
