// Dependencies
import React from 'react';

// Components
import Button from '@components/Button';
import Typography from '@components/Typography';

// Styles
import styles from './TikTokConnectAction.module.scss';

// Types
import {TikTokConnectActionProps} from './types';

const TikTokConnectAction: React.FC<TikTokConnectActionProps> = ({onClick}) => (
  <div className={styles.layout}>
    <Typography variant={'text'} textAlign={'center'} size={'md'} weight={'regular'}>🚀 First step: Link your TikTok account to GivitNFT. This initial connection is key to unlocking all the features we have in store for you. 🌐</Typography>

    <Button
      onClick={onClick}
      showTextOnLoading={true}>
      Connect with TikTok
    </Button>
  </div>
);

export default TikTokConnectAction;
