// Dependencies
import {Action, combineReducers} from 'redux';

/**
 * Reducers
 */

// UI
import mainMenu from './ui/mainMenu/reducer';
import modal from './ui/modal/reducer';
import toast from './ui/toast/reducer';
import loaders from './ui/loaders/reducer';
import settings from './settings/reducer';

// Flows
import auth from './auth/reducer';
import nft from './nft/reducer';
import collection from './collection/reducer';
import reward from './reward/reducer';
import gift from './gift/reducer';
import quest from './quest/reducer';

// Types
import {settingsActionTypes} from './settings/types';

// Exporter
const appReducer = combineReducers({
  auth,
  nft,
  collection,
  reward,
  gift,
  quest,

  settings,
  ui: combineReducers({
    mainMenu,
    modal,
    toast,
    loaders
  })
});

// RootState
export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (action.type === settingsActionTypes.RESET_APP) { state = undefined; }

  return appReducer(state, action);
};

export default rootReducer;
