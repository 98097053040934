// Dependencies
import React, {useCallback} from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';

// Components
import LoginForm, {FormValues} from '@forms/LoginForm';
import Typography from '@components/Typography';

// Styles
import styles from './LogIn.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Assets
import GivitLogo from '@assets/images/logo.png';

import language_es from 'src/locales/es/pages/auth/logIn.json';
import language_en from 'src/locales/en/pages/auth/logIn.json';

function LogIn(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {logIn} = useAuth();
  const {search} = useLocation();
  const {isLoading} = useLoader(LoadersId.IS_LOGIN);
  const query = new URLSearchParams(search);
  const redirectTo = query.get('redirect');

  /**
   * @function handleLoginForm():
   */
  const handleLoginForm = useCallback((values: FormValues) => {
    const {email, password} = values;

    logIn(email, password, redirectTo ?? undefined);
  }, [search]);

  return (
    <div className={styles.body}>
      <div className={styles.header}>
        <img src={GivitLogo} alt={'Welcome to GivitNFT'} />
      </div>
      <div className={styles.layout}>
        <div className={styles.box}>
          <div className={styles.head}>
            <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.title}</Typography>
            <Typography variant={'text'} size={'md'}>{language.subTitle}</Typography>
          </div>
          <LoginForm
            onSubmit={handleLoginForm}
            redirect={redirectTo}
            isLoading={isLoading}
          />
          <div className={styles.foot}>
            <Typography variant={'text'} textAlign={'center'} size={'sm'}>
              {language['signup-placeholder']}
              <Link to={'/sign-in'} className={styles.link}>
                {language['signup-label']}
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
