// Dependencies
import React, {useCallback} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {TailSpin} from 'react-loader-spinner';

// Components
import Button from '@components/Button';

// Styles
import styles from './UserNavigation.module.scss';

// Types
import {ModalsId} from '@type/modals';

// Assets
import {RiLogoutCircleLine, RiPaletteLine/* , RiNftFill */} from 'react-icons/ri';

// Hooks
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal from '@hooks/useModal';

import language_es from 'src/locales/es/components/navigation.json';
import language_en from 'src/locales/en/components/navigation.json';

function UserNavigation(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {user, logOut} = useAuth();
  const {openModal, closeModal} = useModal();

  // Ref
  const navigate = useNavigate();
  const location = useLocation();

  // Loaders
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_AUTH);
  const {isLoading: isLogingOff} = useLoader(LoadersId.IS_LOGING_OFF);

  /**
   * @function handleClickLogout()
   * @description Invoke handler on logout
   */
  const handleClickLogOut = useCallback(() => {
    logOut('/');
    closeModal();
  }, []);

  /**
   * A callback function for handling the click event for signing in.
   *
   * @type {handleClickSignIn}
   */
  const handleClickSignIn = useCallback(() => {
    if (location.pathname.includes('/collection/')) {
      const url = encodeURIComponent(location.pathname);
      navigate('/login?redirect=' + url.toString());
    } else {
      navigate('/login');
    }
    closeModal();
  }, [location]);

  /**
   * A callback function for handling the click event to navigate to the creator page.
   *
   * @type {HandleClickCreator}
   */
  const handleClickCreator = useCallback(() => {
    navigate('/creator');
  }, [navigate]);

  const handleClickStart = useCallback(() => {
    openModal(ModalsId.START_GIVITNFT, {
      action: () => {
      }
    });
  }, []);

  /**
   * A callback function for handling the click event to shot the import NFT modal.
   *
   * @type {handleClickImportNFT}
   */
  /*
  const handleClickImportNFT = useCallback(() => {
    openModal(ModalsId.IMPORT_NFT, {
      action: () => {
      }
    });
  }, []);
  */

  if (isLoading) {
    return null;
  }

  if (!user) {
    return (
      <Button
        size={'md'}
        iconLeft={(<RiLogoutCircleLine />)}
        variant={'solid'}
        color={'primary'}
        onClick={handleClickSignIn}>
        {language.signIn}
      </Button>
    );
  }

  return (
    <div className={styles.layout}>
      {/* User Profile
      <button className={styles.button} onClick={handleClickAccount}>
        <RiAccountCircleLine />
      </button>
      */}

      {/*
      Import NFT Button
      <Button
        variant={'outline'}
        color={'secondary'}
        iconLeft={(<RiNftFill />)}
        onClick={handleClickImportNFT}
      >
        {t('import-nft')}
      </Button>
      */}

      {/* Creator Button */}
      {user.creator ? (
        <Button
          variant={'outline'}
          color={'secondary'}
          iconLeft={(<RiPaletteLine />)}
          onClick={handleClickCreator}
        >
          {language.creator}
        </Button>
      ) : (
        <Button
          variant={'solid'}
          color={'primary'}
          iconLeft={(<RiPaletteLine />)}
          onClick={handleClickStart}
        >
          {language.creator}
        </Button>
      )}

      {/* LogOut Button */}
      {isLogingOff ? (
        <TailSpin width={'20px'} height={'20px'} color={'#333'} />
      ) : (
        <button className={styles.button} onClick={handleClickLogOut}>
          <RiLogoutCircleLine />
        </button>
      )}
    </div>
  );
}

export default UserNavigation;
