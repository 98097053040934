// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';

// Types
import {EditGiftAction} from '@store/gift/types';
import {EditGiftResponse} from '@services/gift/types';
import {LoadersId} from '@type/loaders';
import {IGift} from '@type/gift';
import {ErrorMessage} from '@type/error';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {setGiftData} from '@store/gift/actions';

// Services
import * as services from '@services/gift';

// Utils
import errorHandler from '@store/errorHandler';

// Selectors
import {selectGiftById} from '@store/gift/selector';

/**
 * Handles the process of editing a gift.
 * @generator
 * @function
 * @param {EditGiftAction} action - The action object with the type and payload.
 * @param {number} action.payload.giftId - The ID of the gift to be edited.
 * @param {Object} action.payload.data - The data to be updated for the gift.
 * @returns {SagaIterator<void>} A saga iterator that handles the editing process of the gift.
 */
function * editGiftProcess({payload: {giftId, data}}: EditGiftAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_EDITING_GIFT, true));

    const gift: IGift = yield select(state => selectGiftById(state, giftId));

    const formData = yield call(() => {
      const result = new FormData();

      if (data.rewardPhoto instanceof File && data.rewardBanner instanceof File) {
        result.append('logo', data.rewardPhoto);
        result.append('banner', data.rewardBanner);
      }

      const contentIsPhoto = data.rewardContent instanceof File;

      if (contentIsPhoto) {
        result.append('rewardContent', data.rewardContent);
      }

      result.append('reward', JSON.stringify({
        id: giftId,
        title: data.rewardTitle,
        description: data.rewardDescription,
        url: data.rewardUrl,
        date: data.rewardDate,
        isUnique: data.rewardUnique,
        rewardTitle: data.claimingTitle,
        rewardDescription: data.claimingInstructions,
        rewardTypeId: data.rewardType?.value,
        ...(!contentIsPhoto ? ({
          rewardContent: data.rewardContent
        }) : ({
          rewardMultimediaContent: data.rewardContent
        })),
        rewardContentTypeId: data.rewardContentType?.value
      }));

      return result;
    });

    const response: EditGiftResponse = yield call(services.editGift, giftId, formData);

    if (response) {
      yield put(setGiftData(giftId, response));
      yield put(setIsLoading(LoadersId.IS_EDITING_GIFT, false));

      yield call(() => {
        window.location.href = `/creator/collection/${gift.collection.id}/reward/${response.id}`;
      });
    }
  } catch (e) {
    console.error(e);
    yield call(() => errorHandler(e as ErrorMessage));
    yield put(setIsLoading(LoadersId.IS_EDITING_GIFT, false));
  }
}

export default editGiftProcess;
