export interface INFT {
  id: number;
  state: NFTState;
  status: boolean;
  name: string;
  image: string;
  nftId: number;
  description: string;
  chainId: number
  imageTypeId: number;
  nftAddress: string;
  nftItemId: number;
  properties: NFTProperty[];
  tx?: string;
  collectionId: number;
  collectionName: string;
  collectionDescription: string;
  collectionSmallLogo: string;
  collectionGivitUrl: string;
  smartContractNFTId: number;
  userNftItemId: number;
  creator: string;
  creatorLogo: string;
  creatorUrl: string;
  explorerUrl: string;
  openSeaUrl: string;
  chain: string;
  copies: number;
  freeCopies: number;
  questCopies: number;
  collectionUrl: string;
}

export interface NFTProperty {
  display_type?: string;
  trait_type: string;
  value: string | number | boolean;
}

export enum NFTState {
  READY_TO_CLAIM = 'READY_TO_CLAIM',
  IS_WAITING_CONNECT = 'IS_WAITING_CONNECT',
  IS_WAITING_CHANGE_CHAIN = 'IS_WAITING_CHANGE_CHAIN',
  PROCESSING = 'PROCESSING',
  IS_WAITING_TRANSACTION = 'IS_WAITING_TRANSACTION',
  IS_WAITING_CONFIRMATION = 'IS_WAITING_CONFIRMATION',
  CLAIMED = 'CLAIMED',
  RECENTLY_CLAIMED = 'RECENTLY_CLAIMED',
}
