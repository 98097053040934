// Dependencies
import React, {useCallback, useMemo} from 'react';

// Components
import TikTokFollowAction from './partials/TikTokFollowAction';
import TikTokConnectAction from './partials/TikTokConnectAction';

// Styles
import styles from './TikTokQuest.module.scss';

// Hooks
import useQuests from '@hooks/useQuests';
import useAuth from '@hooks/useAuth';

// Types
import {TikTokQuestProps} from './types';

// Assets
// import {RiFoldersLine, RiSendPlane2Line} from 'react-icons/ri';

function TikTokQuest({collectionName, quest, isMobile}: TikTokQuestProps): React.ReactElement {
  const {tiktokAuthQuest, checkQuestActionIsCompleted} = useQuests();
  const {user} = useAuth();

  const handleClickConnect = useCallback(() => {
    tiktokAuthQuest(collectionName, quest.id);
  }, []);

  const handleClickAction = useCallback(() => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const tiktokUrl = `https://tiktok.com/${handler}`;

    window.open(tiktokUrl, '_blank');
  }, []);

  const handleClickCheckAction = useCallback(() => {
    checkQuestActionIsCompleted(quest);
  }, []);

  const renderQuestAction = useMemo(() => {
    switch (quest.questActionType.id) {
      case 9:
        return (
          <TikTokFollowAction
            avatar={quest.collection?.creator.avatar}
            creator={quest.collection?.creator.name}
            userToFollow={quest.handler}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
          />
        );
    }
  }, [quest.questActionType.id]);

  return (
    <div className={styles.layout}>
      {!user?.userTikTok ? (
        <TikTokConnectAction onClick={handleClickConnect} />
      ) : (
        renderQuestAction
      )}
    </div>
  );
}
export default TikTokQuest;
