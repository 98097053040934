// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Types
import {CreateNFTProps} from './types';

// Hooks
import useCollections from '@hooks/useCollections';
import useLoader, {LoadersId} from '@hooks/useLoader';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './CreateOrEditNFTModal.module.scss';

// Components
import Typography from '@components/Typography';

// Forms
import CreateOrEditNFTForm, {FormValues} from '@forms/CreateOrEditNFTForm';

import language_es from 'src/locales/es/modals/createOrEditNFT.json';
import language_en from 'src/locales/en/modals/createOrEditNFT.json';

function CreateOrEditNFTModal({collectionId, createQuests, nft}: CreateNFTProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {createNFT, editNFT} = useCollections();
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_NFT);

  /**
   * Callback function to handle submitting the creation of a new NFT.
   *
   * @function
   * @name handleSubmitCreateNFT
   * @param {Object} values - Form values object with properties: image {File}, name {string},
   * description {string}, copies {string}, supply {string}.
   * @returns {void}
   */
  const handleSubmitCreateNFT = useCallback((values: FormValues) => {
    if (nft) {
      editNFT(collectionId, {
        id: nft.id,
        name: values.name,
        description: values.description,
        numberOfCopies: Number(values.copies),
        freeCopies: Number(values.freeCopies),
        questCopies: Number(values.questCopies),
        photo: values.image,
        imageTypeId: values.imageTypeId
      });
    } else {
      if (values.image instanceof File && (values.name && (values.copies >= 0) && (values.freeCopies >= 0) && (values.questCopies >= 0))) {
        createNFT(collectionId, {
          name: values.name,
          photo: values.image,
          numberOfCopies: Number(values.copies),
          freeCopies: Number(values.freeCopies),
          questCopies: Number(values.questCopies),
          description: values.description,
          imageTypeId: values.imageTypeId
        });
      }
    }
  }, [collectionId, nft]);

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.row}>
        <Typography variant={'display'} size={'sm'} weight={'semiBold'}>{language.nftTitle}</Typography>
        <Typography>{language.nftDescription}</Typography>
      </div>
      <div className={styles.row}>
        <CreateOrEditNFTForm
          isLoading={isLoading}
          {...nft ? ({
            initialValues: {
              name: nft.name,
              image: nft.image,
              copies: nft.copies,
              freeCopies: nft.freeCopies,
              questCopies: nft.questCopies,
              description: nft.description,
              imageTypeId: nft.imageTypeId,
              quests: createQuests
            }
          }) : ({
            initialValues: {
              image: undefined,
              imageTypeId: 0,
              name: '',
              description: '',
              copies: 0,
              airdrop: false,
              freeCopies: 0,
              quests: createQuests,
              questCopies: 0
            }
          })}
          onSubmit={handleSubmitCreateNFT}
        />
      </div>
    </div>
  );
}

export default CreateOrEditNFTModal;
