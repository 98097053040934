// Dependencies
import React, {useState, useCallback} from 'react';
import {Tweet} from 'react-tweet';
import classNames from 'clsx';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';

// StyleSheet
import styles from './TwitterLikeAction.module.scss';

// Types
import {TwitterLikeActionProps} from './types';
import {RiChat2Line, RiHeart2Line, RiRefreshLine} from 'react-icons/ri';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

function TwitterLikeAction({
  type,
  tweetId,
  onAction,
  onCheck
}: TwitterLikeActionProps): React.ReactElement {
  const {isLoading: isLoadingCompletingQuest} = useLoader(LoadersId.IS_COMPLETING_QUEST);
  const [clickButton, setClickButton] = useState<boolean>(false);

  const handleClickCheckAction = useCallback(() => {
    setClickButton(true);
    onAction();
  }, []);

  const labels: Record<string, {icon: JSX.Element, text: string, action: string}> = {
    2: {
      icon: <RiHeart2Line />,
      text: 'Like this post',
      action: 'Like'
    },
    3: {
      icon: <RiChat2Line />,
      text: 'Reply to this post',
      action: 'Reply'
    },
    4: {
      icon: <RiRefreshLine />,
      text: 'Retweet this post',
      action: 'Retweet'
    }
  };

  return (
    <div className={styles.layout}>
      <div className={classNames([styles.body, styles.quest])}>
        <Typography weight={'semiBold'} size={'sm'}>To proceed, please complete the following action:</Typography>
        <div className={styles.action}>
          <div className={styles.inline}>
            <div className={styles.icon}>
              {labels[type].icon}
            </div>
            <Typography variant={'text'} size={'md'}>
              {labels[type].text}
            </Typography>
          </div>
          <Button size={'sm'} onClick={handleClickCheckAction}>{labels[type].action}</Button>
        </div>
        <Tweet
          id={tweetId}
        />
      </div>

      <div className={styles.body}>
        <Typography variant={'text'} textAlign={'center'} size={'md'} weight={'regular'}>After completion, click this buton to check and complete the quest.</Typography>
        <Button disabled={!clickButton} onClick={onCheck} isLoading={isLoadingCompletingQuest}>Claim as completed</Button>
      </div>
    </div>
  );
}

export default TwitterLikeAction;
