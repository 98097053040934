// Types
import {RequestProps} from './types';
import axios, {AxiosResponse} from 'axios';

// Config
export const backendEndPoint: string | undefined = process.env.REACT_APP_API_BASE_URL;

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json'
};

export async function request<TResponse>(path: string, {
  method,
  baseUrl = null,
  body = undefined,
  params = null,
  headers = {}
}: RequestProps): Promise<TResponse> {
  const url = baseUrl ?? backendEndPoint;

  const opts: RequestInit = {
    method,
    headers: {
      ...DEFAULT_HEADERS,
      ...headers
    },
    credentials: 'include',
    body: body ? JSON.stringify(body) : null
  };

  const urlParams = params ? '?' + Object.keys(params).map(key => key + '=' + params[key]).join('&') : '';
  const uri: string = `${url as string}${path}${urlParams}`;

  const response: Response = await fetch(uri, opts);

  if (response.ok) {
    return await response.json();
  } else {
    const errorText = JSON.parse(await response.text());
    if (response.status === 500 || response.status === 401 || response.status === 501) {
      throw {
        message: errorText.message,
        code: response.status
      };
    }
    throw {
      name: 'error',
      code: response.status
    };
  }
}

export async function requestWithFile<T>(method: RequestProps['method'] = 'POST', path: string, formData: object): Promise<T> {
  try {
    const url = (String(backendEndPoint) + path).toString();

    const response: AxiosResponse<T> = await axios.request({
      url,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      method,
      data: formData,
      withCredentials: true
    });

    return response.data;
  } catch (e: any) {
    console.error(e);
    const errorStatus = e.response.status;
    const errorText = e.response.data.message;
    if (errorStatus === 500) {
      throw new Error(errorText);
    }
    throw {
      name: 'error',
      code: errorStatus
    };
  }
}

export default request;
