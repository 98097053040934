import React, {useCallback, useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import useAuth from '@hooks/useAuth';

import * as service from '@services/quest';
import QuestCallbackTemplate from '../QuestCallbackTemplate';

import {ErrorMessage} from '@type/error';

const InstagramCallbackQuest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {fetchAuth} = useAuth();
  const [message, setMessage] = useState('Processing your Instagram action, coming back to the application.');
  const [collectionName, setCollectionName] = useState('');
  const [quest, setQuest] = useState('');
  const [error, setError] = useState(false);

  const handleClickError = useCallback(() => {
    navigate(`/collection/${collectionName}?questId=${quest}`);
  }, [collectionName, quest]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get('code') as string;
    const state = query.get('state') as string;
    let name = '';
    let questId = '';

    if (state) {
      const parts = state.replace(/[[\]]/g, '').split(',');
      name = parts[0].trim();
      questId = parts[1].trim();

      setCollectionName(name);
      setQuest(questId);
    }

    if (code) {
      // Call your backend's callback API function
      service.instagramAuthQuestCallback(name, quest, code)
        .then(response => {
          if (response) {
            fetchAuth();
            navigate(`/collection/${name}?questId=${questId}`);
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setMessage((error as ErrorMessage).message);
          setError(true);
        });
    } else {
      const errorDescription = query.get('error_description') as string;
      setMessage(errorDescription);
      setError(true);
    }
  }, [location]);

  return <QuestCallbackTemplate message={message} error={error} onClick={handleClickError} />;
};

export default InstagramCallbackQuest;
