// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {FetchCollectionByNameAction} from '@store/collection/types';
import {GetCollectionByIdResponse, GetCollectionNFTsListResponse} from '@services/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setCollectionData} from '@store/collection/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchCollectionByName()
 * @description Fetching collection by name.
 */
function * fetchCollectionByNameProcess({payload: {name}}: FetchCollectionByNameAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.FETCH_COLLECTION_BY_NAME, true));

    const response: GetCollectionByIdResponse = yield call(services.getCollectionByName, name);
    const nfts: GetCollectionNFTsListResponse = yield call(services.getCollectionNFTsList, response.id);

    if (response && nfts) {
      yield put(setCollectionData(response.id, response));
    }

    yield put(setIsLoading(LoadersId.FETCH_COLLECTION_BY_NAME, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.FETCH_COLLECTION_BY_NAME, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchCollectionByNameProcess;
