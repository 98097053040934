// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {FetchCollectionByIdAction} from '@store/collection/types';
import {GetCollectionByIdResponse, GetCollectionNFTsListResponse} from '@services/collection/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/collection';

// Actions
import {setNewCollection} from '@store/collection/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchCollectionById()
 * @description Fetching collection by id.
 */
function * fetchCollectionByIdProcess({payload: {id}}: FetchCollectionByIdAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.FETCH_COLLECTION_BY_ID, true));

    const response: GetCollectionByIdResponse = yield call(services.getCollectionById, id);
    const nfts: GetCollectionNFTsListResponse = yield call(services.getCollectionNFTsList, id);

    if (response && nfts) {
      yield put(setNewCollection({
        ...response,
        nfts
      }));
    }

    yield put(setIsLoading(LoadersId.FETCH_COLLECTION_BY_ID, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.FETCH_COLLECTION_BY_ID, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchCollectionByIdProcess;
