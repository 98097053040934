// API
import request, {requestWithFile} from '../api';

// Types
import {GetGiftListResponse, GetGiftByIdResponse, DeleteGiftResponse, EditGiftResponse, CreateNewGiftResponse, PublishGiftResponse} from './types';

/**
 * @function getPublishedGiftList()
 */
export const getPublishedGiftList = async(): Promise<GetGiftListResponse> =>
  await request('/rewards/published', {
    method: 'GET',
    params: {
      typeId: '2',
      page: '0',
      size: '30'
    }
  });

/**
 * @function getAllGiftList()
 */
export const getAllGiftList = async(): Promise<GetGiftListResponse> =>
  await request('/rewards', {
    method: 'GET',
    params: {
      typeId: '2',
      page: '0',
      size: '30'
    }
  });

export const getGiftListByColectionId = async(collectionId: number, onlyPublished: Boolean): Promise<GetGiftListResponse> => {
  if (onlyPublished) {
    return await request(`/rewards/collection/${collectionId}`, {
      method: 'GET'
    });
  } else {
    return await request(`/rewards/collection/all/${collectionId}`, {
      method: 'GET'
    });
  }
};

/**
 * @function getGiftById()
 */
export const getGiftById = async(id: number): Promise<GetGiftByIdResponse> =>
  await request(`/rewards/${id}`, {
    method: 'GET'
  });

/**
 * @function createNewGift()
 */
export const createNewGift = async(formData: {
  logo: File,
  banner: File,
  reward: {
    title: string;
    description: string;
    url: string;
    date: string;
    rewardTitle: string;
    rewardDescription: string;
    rewardTypeId: number;
    rewardContent: string;
    rewardContentTypeId: number;
  }
}): Promise<CreateNewGiftResponse> =>
  await requestWithFile('POST', '/rewards', formData);

/**
 * @function editGift
 * @param giftId
 * @param formData
 */
export const editGift = async(giftId: number, formData: {
  logo: File | string,
  banner: File | string,
  reward: {
    id: number,
    title: string,
    description: string,
    url: string,
    date: string,
    isUnique: boolean,
    rewardTitle: string,
    rewardDescription: string,
    rewardTypeId: number,
    rewardContent: string,
    rewardMultimediaContent: string,
    rewardContentTypeId: number
  }
}): Promise<EditGiftResponse> =>
  await requestWithFile('PUT', `/rewards/${giftId}`, formData);

/**
 * @function deleteGift(giftId)
 */
export const deleteGift = async(giftId: number): Promise<DeleteGiftResponse> =>
  await request('/rewards/' + giftId.toString(), {
    method: 'DELETE'
  });

/**
 * @function publishGift(giftId)
 */
export const publishGift = async(giftId: number): Promise<PublishGiftResponse> =>
  await request('/rewards/publish/' + giftId.toString(), {
    method: 'POST'
  });
