// Dependencies
import React, {useState, useCallback} from 'react';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';

// StyleSheet
import styles from './TwitterFollowAction.module.scss';

// Types
import {TwitterFollowActionProps} from './types';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

function TwitterFollowAction({
  avatar,
  creator,
  userToFollow,
  onAction,
  onCheck
}: TwitterFollowActionProps): React.ReactElement {
  const {isLoading: isLoadingCompletingQuest} = useLoader(LoadersId.IS_COMPLETING_QUEST);
  const [clickButton, setClickButton] = useState<boolean>(false);

  const handleClickCheckAction = useCallback(() => {
    setClickButton(true);
    onAction();
  }, []);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <img className={styles.photo} width={'42px'} height={'42px'} src={avatar} />
        <div className={styles.title}>
          <Typography variant={'text'} size={'md'} weight={'semiBold'}>{creator}</Typography>
          <Typography variant={'text'} size={'xs'}>{userToFollow}</Typography>
        </div>
        <div className={styles.action}>
          <Button onClick={handleClickCheckAction}>Follow</Button>
        </div>
      </div>
      <div className={styles.body}>
        <Typography variant={'text'} textAlign={'center'} size={'md'} weight={'regular'}>After completion, click this button to check and complete the quest.</Typography>
        <Button disabled={!clickButton} onClick={onCheck} isLoading={isLoadingCompletingQuest}>Claim as completed</Button>
      </div>
    </div>
  );
}

export default TwitterFollowAction;
