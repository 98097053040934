// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Types
import {CreateGiftAction} from '@store/gift/types';
import {CreateNewGiftResponse} from '@services/gift/types';
import {LoadersId} from '@type/loaders';

// Services
import * as services from '@services/gift';

// Actions
import {setNewCollection} from '@store/collection/actions';
import {setIsLoading} from '@store/ui/loaders/actions';
import {ErrorMessage} from '@type/error';

// Utils
import errorHandler from '@store/errorHandler';

/**
 * Handles the process of creating a new gift.
 * @generator
 * @function
 * @param {CreateGiftAction} action - The action object with the type and payload.
 * @param {number} action.payload.collectionId - The ID of the collection to which the gift should be added.
 * @param {Object} action.payload.gift - The data for the new gift.
 * @returns {SagaIterator<void>} A saga iterator that handles the creation process of the new gift.
 */
function * createNewGiftProcess({payload: {collectionId, gift}}: CreateGiftAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_CREATING_GIFT, true));

    const formData = yield call(() => {
      const data = new FormData();

      if (gift.rewardPhoto && gift.rewardPhoto instanceof File) {
        data.append('logo', gift.rewardPhoto);
      }
      if (gift.rewardBanner) data.append('banner', gift.rewardBanner);

      const contentIsPhoto = gift.rewardContent instanceof File;

      if (contentIsPhoto) {
        data.append('rewardContent', gift.rewardContent);
      }

      data.append('reward', JSON.stringify({
        title: gift.rewardTitle,
        description: gift.rewardDescription,
        url: gift.rewardUrl,
        date: gift.rewardDate,
        rewardPhoto: gift.rewardPhoto,
        rewardTitle: gift.claimingTitle,
        rewardDescription: gift.claimingInstructions,
        rewardTypeId: gift.rewardType?.value,
        ...(!contentIsPhoto && ({
          rewardContent: gift.rewardContent
        })),
        rewardContentTypeId: gift.rewardContentType?.value,
        collectionId
      }));

      return data;
    });

    const response: CreateNewGiftResponse = yield call(services.createNewGift, formData);

    if (response) {
      yield put(setNewCollection(response));
      yield put(setIsLoading(LoadersId.IS_CREATING_GIFT, false));
      yield call(() => {
        window.location.href = `/creator/collection/${collectionId}`;
      });
    }
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_CREATING_GIFT, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default createNewGiftProcess;
