// Dependencies
import React, {useCallback, useMemo} from 'react';

// Components
import InstagramConnectAction from './partials/InstagramConnectAction';
import InstagramFollowAction from './partials/InstagramFollowAction';

// Styles
import styles from './InstagramQuest.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useQuests from '@hooks/useQuests';

// Types
import {InstagramQuestProps} from './types';

// Assets
// import {RiFoldersLine, RiSendPlane2Line} from 'react-icons/ri';

function InstagramQuest({collectionName, quest, isMobile}: InstagramQuestProps): React.ReactElement {
  const {instagramAuthQuest, checkQuestActionIsCompleted} = useQuests();
  const {user} = useAuth();

  const handleClickConnect = useCallback(() => {
    instagramAuthQuest(collectionName, quest.id);
  }, []);

  const handleClickAction = useCallback(() => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const instagramUrl = `https://instagram.com/${handler}`;

    window.open(instagramUrl, '_blank');
  }, []);

  const handleClickCheckAction = useCallback(() => {
    checkQuestActionIsCompleted(quest);
  }, []);

  const renderQuestAction = useMemo(() => {
    switch (quest.questActionType.id) {
      case 7:
        return (
          <InstagramFollowAction
            avatar={quest.collection?.creator.avatar}
            creator={quest.collection?.creator.name}
            userToFollow={quest.handler}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
          />
        );
    }
  }, [quest.questActionType.id]);

  return (
    <div className={styles.layout}>
      {!user?.userInstagram ? (
        <InstagramConnectAction onClick={handleClickConnect} />
      ) : (
        renderQuestAction
      )}
    </div>
  );
}

export default InstagramQuest;
