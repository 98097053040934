// Dependencies
import React, {useEffect, useCallback} from 'react';
import {format} from 'date-fns';
import classNames from 'clsx';
import {useNavigate, useParams} from 'react-router-dom';

// Styles
import styles from './RewardPreview.module.scss';

// Hooks
import useGifts from '@hooks/useGifts';
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal, {ModalsId} from '@hooks/useModal';
import useRewards from '@hooks/useRewards';
import useResponsive from '@hooks/useResponsive';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import QuillTextInput from '@components/QuillTextInput';
import Loader from '@components/Loader';

import language_es from 'src/locales/es/pages/creator/rewardPreview.json';
import language_en from 'src/locales/en/pages/creator/rewardPreview.json';

function RewardPreview(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {id, rewardId} = useParams<{id: string, rewardId: string}>();
  const {
    gift,
    fetchGiftById,
    deleteGift,
    publishGift
  } = useGifts(Number(rewardId));
  const navigate = useNavigate();
  const {isMobile} = useResponsive();
  const {isLoading: isLoadingFetchingGiftList} = useLoader(LoadersId.IS_FETCHING_GIFT_LIST);
  const {isLoading: isLoadingDownloadingRewardsCSV} = useLoader(LoadersId.IS_DOWNLOADING_REWARDS_CSV);
  const {isLoading: isLoadingFetchingRewards} = useLoader(LoadersId.IS_FETCHING_REWARDS);
  const {fetchRewards, downloadRewardsCSV} = useRewards();
  const {openModal, closeModal} = useModal();

  useEffect(() => {
    fetchGiftById(Number(rewardId));
  }, []);

  /**
   * Callback function to handle the click event for editing a reward (gift).
   *
   * When invoked, this function navigates to the editing page for the specified reward using the `history.push` method.
   *
   * @function
   * @name handleClickEditReward
   * @returns {void}
   */
  const handleClickEditReward = useCallback(() => {
    void navigate(`/creator/collection/${id}/reward/${rewardId}/edit`);
  }, [id, rewardId]);

  /**
   * Callback function to handle the click event for deleting a reward (gift).
   *
   * When invoked, this function triggers the process to delete the specified reward using the `deleteGift` function.
   *
   * @function
   * @name handleClickDeleteReward
   * @returns {void}
   */
  const handleClickDeleteReward = useCallback(() => {
    openModal(ModalsId.CONFIRM_MESSAGE, {
      title: language.deleteRewardConfirm,
      description: [language.deleteRewardConfirmationMessage1, language.deleteRewardConfirmationMessage2],
      action: () => {
        closeModal();
        deleteGift(Number(rewardId));
      }
    });
  }, [rewardId]);

  /**
   * Callback function to handle the click event for publishing a reward (gift).
   *
   * When invoked, this function triggers the process to publish the specified reward using the `publishGift` function.
   *
   * @function
   * @name handleClickPublishReward
   * @returns {void}
   */
  const handleClickPublishReward = useCallback(() => {
    openModal(ModalsId.CONFIRM_MESSAGE, {
      title: language.publishRewardConfirm,
      description: [language.publishRewardConfirmationMessage1, language.publishRewardConfirmationMessage2],
      action: () => {
        closeModal();
        publishGift(Number(rewardId));
      }
    });
  }, [rewardId]);

  /**
   * Callback function to handle downloading the CSV file for rewards.
   *
   * When invoked, this function triggers the process to download the CSV file containing the rewards using the `downloadRewardsCSV` function.
   *
   * @function
   * @name handleDownloadCSV
   * @returns {void}
   */
  const handleDownloadCSV = useCallback(() => {
    downloadRewardsCSV(Number(rewardId));
  }, []);

  /**
   * Callback function to handle the click event for previewing a reward (gift).
   *
   * When invoked, this function opens the modal to preview the specified reward using the `openModal` function.
   *
   * @function
   * @name handleClickPreviewReward
   * @returns {void}
   */
  const handleClickPreviewReward = useCallback(() => {
    fetchRewards('creator', Number(rewardId));
  }, [rewardId]);

  if (isLoadingFetchingGiftList || !gift) return <Loader />;

  return (
    <div>
      <div className={styles.banner}>
        <img src={gift?.banner} alt={gift?.title} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <img src={gift?.logo} alt={gift?.title} />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h2'} weight={'semiBold'} textAlign={isMobile ? 'center' : 'left'}>{gift?.title}</Typography>
            <Typography variant={'text'} size={'sm'} component={'span'} weight={'regular'} textAlign={isMobile ? 'center' : 'left'}><a href={gift?.url} rel='noreferrer' target='_blank'>{gift?.url}</a></Typography>
            <div className={styles.inline}>
              <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'} textAlign={isMobile ? 'center' : 'left'}>{language.rewardType}</Typography>
              <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'} textAlign={isMobile ? 'center' : 'left'}>{gift.rewardTypeId === 1 ? language.event : language.gift}</Typography>
            </div>

            <div className={styles.inline}>
              <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'} textAlign={isMobile ? 'center' : 'left'}>{language.date}</Typography>
              <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'} textAlign={isMobile ? 'center' : 'left'}>{gift.date && format(new Date(gift.date), 'dd/MM/yyyy HH:mm')}</Typography>
            </div>
          </div>
          {!gift.published && (<div className={classNames([styles.row, styles.actions])}>
            <div>
              <Button size={isMobile ? 'sm' : 'md'} color={'tertiary'} onClick={handleClickEditReward}>{language.editReward}</Button>
            </div>
            <div>
              <Button size={isMobile ? 'sm' : 'md'} color={'error'} onClick={handleClickDeleteReward}>{language.deleteReward}</Button>
            </div>
            <div>
              <Button size={isMobile ? 'sm' : 'md'} onClick={handleClickPublishReward}>{language.publishReward}</Button>
            </div>
          </div>)}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.field}>
          <div className={styles.row}>
            <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.descriptionOfReward}</Typography>
            <QuillTextInput
              theme='snow'
              value={gift?.description}
              readOnly={true}
              modules={{toolbar: false}}
            />
          </div>

          <div className={styles.row}>
            <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.rewardClaimingTitle}</Typography>
            <Typography variant={'text'} size={'md'} component={'span'} weight={'regular'}>{gift.rewardTitle}</Typography>
          </div>

          <div className={styles.row}>
            <Typography variant={'text'} size={'md'} component={'h4'} weight={'semiBold'}>{language.instructionsOfReward}</Typography>
            <QuillTextInput
              theme='snow'
              value={gift?.rewardDescription}
              readOnly={true}
              modules={{toolbar: false}}
            />
          </div>

          {gift.isUnique ? (
            <div className={classNames([styles.actions, styles.preview])}>
              <div className={styles.row}>
                <Button
                  variant={'solid'}
                  type={'button'}
                  color={'primary'}
                  isLoading={isLoadingDownloadingRewardsCSV}
                  onClick={handleDownloadCSV}>
                  {language.downloadCodesInCSV}
                </Button>
              </div>
            </div>
          ) : (
            <div className={classNames([styles.actions, styles.preview])}>
              <div className={styles.row}>
                <Button
                  variant={'solid'}
                  type={'button'}
                  color={'primary'}
                  isLoading={isLoadingFetchingRewards}
                  onClick={handleClickPreviewReward}>
                  {language.rewardPreview}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RewardPreview;
