// Dependencies
import React, {useCallback} from 'react';
import classNames from 'clsx';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import NFTViewer from '@components/NFTViewer';

// Styles
import styles from './CollectionNFTCard.module.scss';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useModal, {ModalsId} from '@hooks/useModal';

// Types
import {CollectionNFTCardProps} from './types';

// Assets
import {RiMailSendLine} from 'react-icons/ri';

import language_es from 'src/locales/es/components/cards/collectionNFTCard.json';
import language_en from 'src/locales/en/components/cards/collectionNFTCard.json';

function CollectionNFTCard({user, isCreator, canClaim, isClaimed, isMobile, onLogin, onFreeClaim, onQuestClaim, ...collectionNft}: CollectionNFTCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isLoading} = useLoader(LoadersId.IS_CREATE_OR_EDIT_NFT);
  const {openModal, closeModal} = useModal();

  const handlePressFreeClaimButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (user) {
      openModal(ModalsId.CONFIRM_CLAIM_AIRDROP_NFT, {
        nft: collectionNft,
        action: () => {
          closeModal();
          onFreeClaim(collectionNft);
        }
      });
    } else {
      onLogin();
    }
  }, [collectionNft]);

  const handlePressQuestClaimButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openModal(ModalsId.CONFIRM_CLAIM_AIRDROP_NFT, {
      nft: collectionNft,
      action: () => {
        closeModal();
        onQuestClaim(collectionNft);
      }
    });
  }, [collectionNft]);

  const handlePressSendMultipleNFTs = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    openModal(ModalsId.SEND_MULTIPLE_NFTS, {
      nft: collectionNft,
      action: () => {
        closeModal();
      }
    });
  }, [collectionNft]);

  return (
    <div className={styles.layout}>
      {/* Title */}
      <div className={styles.field}>
        <div className={styles.title}>
          <Typography variant={'display'} size={'xs'} weight={'bold'}>{collectionNft.name}</Typography>
        </div>
      </div>

      {/* Image/Video */}
      <div className={styles.field}>
        <NFTViewer
          onClick={() => {}}
          isMobile={isMobile}
          mediaTypeId={collectionNft?.imageTypeId}
          mediaSrc={collectionNft?.image}
        />
      </div>

      {/* Description */}
      <div className={classNames([styles.field, styles.descriptionRow])}>
        <Typography component={'p'} className={styles.description} variant={'text'} size={'xs'} weight={'regular'}>{collectionNft.description}</Typography>
      </div>

      {/* Details */}
      <div className={styles.field}>
        <div className={styles.nftsRow}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.nftsCreated}: </Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>{collectionNft.copies}</Typography>
        </div>
        {isCreator && collectionNft.freeCopies > 0 && (
          <div className={styles.nftsRow}>
            <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.availableForCommunity}:</Typography>
            <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>{collectionNft.freeCopies > 0 ? collectionNft.freeCopies : 'Fully distributed'}</Typography>
          </div>
        )}
        {isCreator && collectionNft.questCopies > 0 && (
          <div className={styles.nftsRow}>
            <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.availableForCommunity}:</Typography>
            <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>{collectionNft.questCopies > 0 ? collectionNft.questCopies : 'Fully distributed'}</Typography>
          </div>
        )}
      </div>

      {/* Action */}
      {!isClaimed && (
        <div className={styles.field}>
          {isCreator && (collectionNft.copies - collectionNft.freeCopies - collectionNft.questCopies > 0) && (
            <Button
              className={styles.claimNFTButton}
              onClick={handlePressSendMultipleNFTs}
              iconLeft={(<RiMailSendLine />)}>
              {language.sendMultipleNfts}
            </Button>
          )}

          {(!isCreator && collectionNft.freeCopies > 0) && (
            <Button
              className={styles.claimNFTButton}
              onClick={handlePressFreeClaimButton}
              isLoading={isLoading}
              showTextOnLoading={true}>
              {user ? language.claimForFree : language.signInToClaim}
            </Button>
          )}

          {(!isCreator && collectionNft.questCopies > 0) && (
            <Button
              className={styles.claimNFTButton}
              onClick={handlePressQuestClaimButton}
              isLoading={isLoading}
              disabled={!canClaim}
              showTextOnLoading={true}>
              {canClaim ? language.claimForFree : language.completeAllQuestsToClaim}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
export default CollectionNFTCard;
