// Dependencies
import React, {useCallback, useMemo} from 'react';

// Components
import TwitterFollowAction from './partials/TwitterFollowAction';
import TwitterLikeAction from './partials/TwitterLikeAction';
import TwitterPostAction from './partials/TwitterPostAction';
import TwitterConnectAction from './partials/TwitterConnectAction';

// Styles
import styles from './TwitterQuest.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useQuests from '@hooks/useQuests';

// Types
import {TwitterQuestProps} from './types';

// Assets
// import {RiFoldersLine, RiSendPlane2Line} from 'react-icons/ri';

function TwitterQuest({collectionName, quest, isMobile, checkEnabled}: TwitterQuestProps): React.ReactElement {
  const {twitterAuthQuest, checkQuestActionIsCompleted} = useQuests();
  const {user} = useAuth();

  const twitterFollow = () => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const twitterUrl = `https://twitter.com/intent/follow?screen_name=${handler}`;

    window.open(twitterUrl, '_blank');
  };

  const twitterLike = () => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const twitterUrl = `https://twitter.com/intent/like?tweet_id=${handler}`;

    window.open(twitterUrl, '_blank');
  };

  const twitterReply = () => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const twitterUrl = `https://twitter.com/intent/tweet?in_reply_to=${handler}&text=`;

    window.open(twitterUrl, '_blank');
  };

  const twitterRetweet = () => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const twitterUrl = `https://twitter.com/intent/retweet?tweet_id=${handler}`;

    window.open(twitterUrl, '_blank');
  };

  const twitterTweet = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?&text=${encodeURIComponent(quest.handler)}`;

    window.open(twitterUrl, '_blank');
  };

  const twitterJoinSpaces = () => {
  };

  const handleClickConnect = useCallback(() => {
    twitterAuthQuest(collectionName, quest.id);
  }, []);

  const handleClickAction = useCallback(() => {
    switch (quest.questActionType.id) {
      case 1:
        twitterFollow();
        break;
      case 2:
        twitterLike();
        break;
      case 3:
        twitterReply();
        break;
      case 4:
        twitterRetweet();
        break;
      case 5:
        twitterTweet();
        break;
      case 6:
        twitterJoinSpaces();
        break;
    }
  }, []);

  const handleClickCheckAction = useCallback(() => {
    checkQuestActionIsCompleted(quest);
  }, []);

  const renderQuestAction = useMemo(() => {
    switch (quest.questActionType.id) {
      case 1:
        return (
          <TwitterFollowAction
            avatar={quest.collection?.creator.avatar}
            creator={quest.collection?.creator.name}
            userToFollow={quest.handler}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
          />
        );

      case 2:
      case 3:
      case 4:
        return (
          <TwitterLikeAction
            type={quest.questActionType.id}
            tweetId={quest.handler}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
          />
        );
      case 5:
        return (
          <TwitterPostAction
            hashtag={quest.handler}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
          />
        );
    }
  }, [quest.questActionType.id]);

  return (
    <div className={styles.layout}>
      {!user?.userTwitter ? (
        <TwitterConnectAction onClick={handleClickConnect} />
      ) : (
        renderQuestAction
      )}
    </div>
  );
}
export default TwitterQuest;
