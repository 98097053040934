// Dependencies
import React, {useCallback} from 'react';
import {FormikProps, useFormik} from 'formik';

// Hooks
import useForm from '@hooks/useForm';

// Components
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import BannerUpload from '@components/BannerUpload';
import AvatarUpload from '@components/AvatarUpload';

// Types
import {EditLinksFormProps, FormValues} from './types';

// StyleSheet
import styles from './EditProfileForm.module.scss';

// Validation
import EditProfileFormValidation from './validations';

import language_es from 'src/locales/es/forms/editProfileForm.json';
import language_en from 'src/locales/en/forms/editProfileForm.json';

function EditProfileForm({
  initialValues,
  isLoading = false,
  onSubmit,
  onCancel
}: EditLinksFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues: {
      name: initialValues?.name ?? '',
      banner: initialValues?.banner ?? '',
      avatar: initialValues?.avatar ?? '',
      url: initialValues?.url ?? ''
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: EditProfileFormValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  const handleDropPhoto = useCallback((field: keyof FormValues, photos: File[]) => {
    if (photos.length === 1) {
      const file = photos[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        const photo = Object.assign(photos[0], {
          preview: URL.createObjectURL(file)
        });
        handleChangeField(field, photo);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles.banner}>
        <BannerUpload
          className={styles.bannerContainer}
          onDrop={(photos) =>
            handleDropPhoto('banner', photos)}
          photo={formik.values.banner}
        />
        <div className={styles.avatar}>
          <AvatarUpload
            onDrop={(photos) =>
              handleDropPhoto('avatar', photos)}
            photo={formik.values.avatar}
          />
        </div>
      </div>
      <div>
        <div className={styles.list}>
          <TextInput
            name={'name'}
            label={language.nameLabel}
            error={getErrorFromField('name')}
            value={formik.values.name}
            onChangeText={(text) => handleChangeField('name', text)}
          />
          <TextInput
            name={'url'}
            label={language.urlLabel}
            error={getErrorFromField('url')}
            value={formik.values.url}
            onChangeText={(url) => handleChangeField('url', url)}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} disabled={isLoading} color={'primary'} onClick={onCancel}>
              {language.cancelButton}
            </Button>
            <Button type={'submit'} isLoading={isLoading} variant={'solid'} color={'primary'} disabled={!formik.isValid}>
              {language.saveChangesButton}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export type {FormValues};
export default EditProfileForm;
