// Dependencies
import {SagaIterator} from 'redux-saga';
import {call, put} from 'redux-saga/effects';

// Actions
import {setIsLoading} from '@store/ui/loaders/actions';
import {LoadersId} from '@type/loaders';
import {setCollectionNFTsList} from '@store/collection/actions';

// Services
import * as collectionServices from '@services/collection';

// Types
import {GetCollectionNFTsListResponse} from '@services/collection/types';
import {normalizeState} from '@utils/state';
import {INFTCollection} from '@type/nftCollection';
import {FetchCollectionNFTsListAction} from '@store/collection/types';

// Error Handler
import errorHandler from '@store/errorHandler';
import {ErrorMessage} from '@type/error';

/**
 * @function fetchCollectionNFTsListProcess(collectionId)
 * @description Fetching Collection List
 */
function * fetchCollectionNFTsListProcess({payload: {collectionId}}: FetchCollectionNFTsListAction): SagaIterator<void> {
  try {
    yield put(setIsLoading(LoadersId.IS_FETCHING_COLLECTION_NFTS_LIST, true));

    const response: GetCollectionNFTsListResponse = yield call(collectionServices.getCollectionNFTsList, collectionId);

    const normalizedItems: {[key: string]: INFTCollection} = normalizeState(
      response.records?.map((collection) => ({
        ...collection
      }))
      , 'id');

    yield put(setCollectionNFTsList(normalizedItems));

    yield put(setIsLoading(LoadersId.IS_FETCHING_COLLECTION_NFTS_LIST, false));
  } catch (e) {
    console.error(e);
    yield put(setIsLoading(LoadersId.IS_FETCHING_COLLECTION_NFTS_LIST, false));
    yield call(() => errorHandler(e as ErrorMessage));
  }
}

export default fetchCollectionNFTsListProcess;
