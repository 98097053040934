// Dependencies
import React, {useCallback, useMemo} from 'react';

// Components
import YouTubeSubscribeAction from './partials/YouTubeSubscribeAction';

// Styles
import styles from './YouTubeQuest.module.scss';

// Hooks
import useAuth from '@hooks/useAuth';
import useQuests from '@hooks/useQuests';

// Types
import {YouTubeQuestProps} from './types';

// Assets
// import {RiFoldersLine, RiSendPlane2Line} from 'react-icons/ri';

function YouTubeQuest({collectionName, quest, isMobile, checkEnabled}: YouTubeQuestProps): React.ReactElement {
  const {checkQuestActionIsCompleted} = useQuests();
  const {user} = useAuth();

  const youTubeSubscribe = () => {
    let handler = quest.handler;
    if (handler.startsWith('@')) handler = handler.substring(1);
    const youTubeUrl = `https://youtube.com/${handler}`;

    window.open(youTubeUrl, '_blank');
  };

  const handleClickConnect = useCallback(() => {
  }, []);

  const handleClickAction = useCallback(() => {
    switch (quest.questActionType.id) {
      case 10:
        youTubeSubscribe();
        break;
    }
  }, []);

  const handleClickCheckAction = useCallback(() => {
    checkQuestActionIsCompleted(quest);
  }, []);

  const renderQuestAction = useMemo(() => {
    switch (quest.questActionType.id) {
      case 10:
        return (
          <YouTubeSubscribeAction
            avatar={quest.collection?.creator.avatar}
            creator={quest.collection?.creator.name}
            channelId={quest.handler}
            checkEnabled={checkEnabled}
            user={user}
            collectionName={collectionName}
            questId={quest.id}
            onAction={handleClickAction}
            onCheck={handleClickCheckAction}
            onConnection={handleClickConnect}
          />
        );
    }
  }, [quest.questActionType.id]);

  return (
    <div className={styles.layout}>
      {renderQuestAction}
    </div>
  );
}
export default YouTubeQuest;
