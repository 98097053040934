// Dependencies
import React, {useCallback, useEffect} from 'react';
import {FormikProps, useFormik} from 'formik';
import classNames from 'clsx';

// StyleSheet
import styles from './NewCollectionForm.module.scss';

// Components
import AvatarUpload from '../../components/AvatarUpload';
import BannerUpload from '../../components/BannerUpload';
import Button from '@components/Button';
import SocialLinks, {generateLinksItem} from '@components/SocialLinks';
import TextInput from '@components/TextInput';
import Selector from '@components/Selector';
import CheckInput from '@components/CheckInput';
import Typography from '@components/Typography';
import QuillTextInput from '@components/QuillTextInput';

// Types
import {FormValues, NewCollectionFormProps} from './types';
import {FormValues as LinksFormValues} from '@forms/EditLinksForm';

// Validation
import NewCollectionValidation from './validations';

// Hooks
import useForm from '@hooks/useForm';
import useModal, {ModalsId} from '@hooks/useModal';
import useResponsive from '@hooks/useResponsive';
import useAuth from '@hooks/useAuth';

// Config
import {networks} from '@utils/web3';

import language_es from 'src/locales/es/forms/newCollectionForm.json';
import language_en from 'src/locales/en/forms/newCollectionForm.json';

const initialDefaultValues: FormValues = {
  chain: networks()[0],
  avatar: undefined,
  banner: undefined,
  name: '',
  givitUrl: '',
  description: '',
  isPublic: true,
  canCreateQuests: false,
  url: '',
  discord: '',
  twitter: '',
  openSeaUrl: '',
  instagramUrl: '',
  calendy: ''
};

export type {FormValues};

export default function NewCollectionForm({id, initialValues = initialDefaultValues, data, isLoading, onCancel, onSubmit}: NewCollectionFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {openModal, closeModal} = useModal();
  const {isMobile} = useResponsive();
  const {user} = useAuth();

  if (id === undefined) initialValues.avatar = user?.creator?.avatar;

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues,
    validateOnMount: true,
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema: NewCollectionValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  const formatForURL = (name: string | undefined) => {
    if (!name) return null;
    // Convert string to lowercase
    // Replace spaces with hyphens
    // Remove invalid URL characters using a regex
    const urlFormatted = name.toLowerCase().trim()
      .replace(/[^a-z0-9\s-]/g, '') // remove invalid characters
      .replace(/\s+/g, '-'); // replace multiple spaces with a single hyphen
    return urlFormatted;
  };

  /**
   * Callback function to handle dropping photos into a field.
   *
   * @function
   * @name handleDropPhoto
   * @param {string} field - The name of the field where the photo is dropped.
   * @param {File[]} photos - An array of File objects representing the dropped photos.
   * @returns {void}
   */
  const handleDropPhoto = useCallback((field: keyof FormValues, photos: File[]) => {
    if (photos.length === 1) {
      const file = photos[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        const photo = Object.assign(photos[0], {
          preview: URL.createObjectURL(file)
        });
        handleChangeField(field, photo);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  /**
   * Callback function to handle adding a social link to the collection.
   *
   * @function
   * @name handleClickAddSocialLink
   * @returns {void}
   */
  const handleClickAddSocialLink = useCallback(() => {
    openModal(ModalsId.EDIT_COLLECTION_LINKS, {
      initialValues: {
        discord: formik.values.discord ?? '',
        instagram: formik.values.instagramUrl ?? '',
        openSea: formik.values.openSeaUrl ?? '',
        twitter: formik.values.twitter ?? '',
        url: formik.values.url ?? '',
        calendy: formik.values.calendy ?? ''
      },
      onSave: (values: LinksFormValues) => {
        handleChangeField('discord', values.discord);
        handleChangeField('instagramUrl', values.instagram);
        handleChangeField('openSeaUrl', values.openSea);
        handleChangeField('twitter', values.twitter);
        handleChangeField('url', values.url);
        handleChangeField('calendy', values.calendy);
        closeModal();
      }
    });
  }, [data, handleChangeField, formik.values]);

  useEffect(() => {
    if (formik.values.name !== '') {
      const formatted = formatForURL(formik.values.name);
      formik.setFieldValue('givitUrl', formatted).catch((e) => console.error(e));
      handleChangeField('givitUrl', formatted);
    }
  }, [formik.values.name]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <BannerUpload
        onDrop={(photos) =>
          handleDropPhoto('banner', photos)}
        photo={formik.values.banner}
      />
      <div className={styles.name}>
        <div className={styles.avatar}>
          <AvatarUpload
            onDrop={(photos) => handleDropPhoto('avatar', photos)}
            photo={formik.values.avatar}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h2'} weight={'semiBold'}>{formik.values.name === '' ? language.collectionNamePlaceholder : formik.values.name}</Typography>
            <div className={styles.social}>
              <SocialLinks
                data={generateLinksItem({
                  discord: formik.values.discord ?? '',
                  instagram: formik.values.instagramUrl ?? '',
                  openSea: formik.values.openSeaUrl ?? '',
                  twitter: formik.values.twitter ?? '',
                  url: formik.values.url ?? '',
                  calendy: formik.values.calendy ?? ''
                })}
                onClickAdd={handleClickAddSocialLink}
              />
              <Typography variant={'text'} size={'md'} component={'p'} weight={'regular'}>{language.addLinks}</Typography>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames([styles.container, styles.body])}>
        <div className={styles.grid}>
          <div className={styles.network}>
            <Typography variant={'text'} weight={'regular'} size={isMobile ? 'sm' : 'md'}>{language.networkSelect}</Typography>
            <Selector
              placeholder={language.deploymentNetworkPlaceholder}
              options={networks()}
              value={formik.values.chain}
              onChange={(item) => handleChangeField('chain', item)}
            />
          </div>

          <div className={styles.inline}>
            <TextInput
              name={'name'}
              placeholder={language.collectionNamePlaceholder}
              value={formik.values.name}
              error={getErrorFromField('name')}
              onChangeText={(name) => handleChangeField('name', name)}
            />
          </div>

          <QuillTextInput
            className={styles.description}
            placeholder={language.descriptionPlaceholder}
            theme='snow'
            value={formik.values.description}
            onChange={(value) => handleChangeField('description', value === '<p><br></p>' ? '' : value)}
            error={getErrorFromField('description')}
          />

          <Typography variant={'text'} weight={'regular'} size={isMobile ? 'sm' : 'md'}>{language.selectOptions}</Typography>

          <div className={classNames([styles.inline, styles.row])}>
            <CheckInput
              label={language.createQuestsLabel}
              description={language.createQuestsDescription}
              checked={formik.values.canCreateQuests}
              disabled={user?.creator?.license ? !user?.creator?.license.quests : false}
              onChange={() => {
                handleChangeField('canCreateQuests', !formik.values.canCreateQuests);
              }}
            />
          </div>
          <div className={classNames([styles.inline, styles.row])}>
            <CheckInput
              label={language.privateCollectionLabel}
              description={language.privateCollectionDescription}
              checked={!formik.values.isPublic}
              disabled={user?.creator?.license ? !user?.creator?.license.privateCollections : false}
              onChange={() => {
                handleChangeField('isPublic', !formik.values.isPublic);
              }}
            />
          </div>
        </div>

        <div className={classNames([styles.inline, styles.url])}>
          <Typography size={isMobile ? 'sm' : 'md'} className={styles.url}>{process.env.REACT_APP_ENVIRONMENT_URL}/collections/</Typography>
          <TextInput
            name={'givitUrl'}
            placeholder={language.publicURLPlaceholder}
            value={formik.values.givitUrl}
            error={getErrorFromField('givitUrl')}
            onChangeText={(givitUrl) => handleChangeField('givitUrl', givitUrl)}
          />
        </div>

        <div className={styles.actions}>
          <div>
            <Button
              variant={'outline'}
              color={'primary'}
              type={'button'}
              onClick={onCancel}
              disabled={isLoading}>
              {language.cancelButton}
            </Button>
          </div>

          <div>
            <Button
              variant={'solid'}
              color={'primary'}
              type={'submit'} disabled={!formik.isValid || isLoading} isLoading={isLoading}>
              {language.saveButton}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
