// Dependencies
import React from 'react';
import Web3 from 'web3';
import {Web3Provider} from '@ethersproject/providers';

// Chains
import {IChain} from '@type/chain';

// Assets
import EthereumIcon from '@assets/blockchains/ethereum.svg';
import BNBIcon from '@assets/blockchains/bnb.svg';
import PolygonIcon from '@assets/blockchains/polygon.svg';
import AvalancheIcon from '@assets/blockchains/avalanche.svg';
import ArbitrumIcon from '@assets/blockchains/arbitrum.svg';
import OptimismIcon from '@assets/blockchains/optimism.svg';
// import ZkSyncIcon from '@assets/blockchains/zksync.svg';

interface IGetChainTarget {
  id: string;
  name: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  },
  rpcUrl: string;
  privateUrl: string;
  scanLink: string;
}

interface ChainsType {
  ETH: number,
  BSC: number,
  POLYGON: number,
  AVALANCHE: number,
  ARBITRUM: number,
  OPTIMISM: number,
  GOERLI_TESTNET: number,
  SEPOLIA_TESTNET: number,
  BSC_TESTNET: number,
  POLYGON_MUMBAI: number,
  AVALANCHE_FUJI: number,
  ARBITRUM_GOERLI: number,
  OPTIMISM_TESTNET: number
};

const chainsTypeId: ChainsType = {
  ETH: 1,
  BSC: 56,
  POLYGON: 137,
  AVALANCHE: 43114,
  ARBITRUM: 42161,
  OPTIMISM: 10,
  GOERLI_TESTNET: 5,
  SEPOLIA_TESTNET: 11155111,
  BSC_TESTNET: 97,
  POLYGON_MUMBAI: 80001,
  AVALANCHE_FUJI: 43113,
  ARBITRUM_GOERLI: 421613,
  OPTIMISM_TESTNET: 69
};

export const getChainData = (chainId: number): IChain | undefined => {
  const chainKeyId = Object.keys(chainsTypeId).find(key => chainsTypeId[key as keyof ChainsType] === chainId);
  if (!chainKeyId) return undefined;
  return chains[chainKeyId as keyof ChainsType];
};

const chains: Record<keyof ChainsType, IChain> = {
  ETH: {
    chainId: 1,
    name: 'mainnet',
    display: 'Ethereum',
    mainnet: true,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://mainnet.infura.io/v3/',
    privateUrl: '',
    explorerUrl: 'https://etherscan.io/',
    icon: <EthereumIcon />
  },
  BSC: {
    chainId: 56,
    name: 'bsc',
    display: 'Binance SmartChain',
    mainnet: true,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'BSC',
      symbol: 'BSC',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://bsc-dataseed1.binance.org/',
    privateUrl: 'https://bsc-dataseed1.binance.org/',
    explorerUrl: 'https://bscscan.com/',
    icon: <BNBIcon />
  },
  POLYGON: {
    chainId: 137,
    name: 'polygon-mainnet',
    display: 'Polygon',
    mainnet: true,
    available: true,
    addresses: {
      nftAirdropper: '0x11BB700Fa1dC12203B9337EA67E0d6b27035fA83',
      forwarder: '0xc4864716FeeABbc7c2ef88eC433C4c0D0C0b176e',
      factory: '0x1AaB868e9b5a535FdC43A77c6B8b467172e5969d'
    },
    currency: {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://polygon-mainnet.infura.io/v3/',
    privateUrl: 'https://polygon-rpc.com/',
    explorerUrl: 'https://polygonscan.com/',
    icon: <PolygonIcon />
  },
  AVALANCHE: {
    chainId: 43114,
    name: 'avalanche',
    display: 'Avalanche C-Chain',
    mainnet: true,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18
    },
    marketplaceUrl: 'https://nftrade.com/',
    rpcUrl: 'https://avalanche-mainnet.infura.io/v3/',
    privateUrl: 'https://api.avax.network/ext/bc/C/rpc',
    explorerUrl: 'https://snowtrace.io/',
    icon: <AvalancheIcon />
  },
  ARBITRUM: {
    chainId: 42161,
    name: 'arbitrum-one',
    display: 'Arbitrum',
    mainnet: true,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Arbitrum',
      symbol: 'ARB',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://arbitrum-mainnet.infura.io/v3/',
    privateUrl: 'https://arb1.arbitrum.io/rpc',
    explorerUrl: 'https://arbiscan.io/',
    icon: <ArbitrumIcon />
  },
  OPTIMISM: {
    chainId: 10,
    name: 'optimistic-ethereum',
    display: 'Optimism',
    mainnet: true,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Optimism',
      symbol: 'OPT',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://optimism-mainnet.infura.io/v3/',
    privateUrl: 'https://mainnet.optimism.io',
    explorerUrl: 'https://optimistic.etherscan.io/',
    icon: <OptimismIcon />
  },
  GOERLI_TESTNET: {
    chainId: 5,
    name: 'goerli',
    display: 'Goerli',
    mainnet: false,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://testnets.opensea.io/assets/goerli/',
    rpcUrl: 'https://goerli.infura.io/v3/',
    privateUrl: '',
    explorerUrl: 'https://goerli.etherscan.io/',
    icon: <EthereumIcon />
  },
  SEPOLIA_TESTNET: {
    chainId: 11155111,
    name: 'sepolia',
    display: 'Sepolia',
    mainnet: false,
    available: true,
    addresses: {
      nftAirdropper: '0xE0FC4A9774133a304fc0B91768f36a44Cd090e73',
      forwarder: '0xb79645a8AE380b1CC44dA9E289aAbaCCd6dF39b9',
      factory: '0xB7B8F386c9736d5dA4931B5A354AC6801eCe24d8'
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://testnets.opensea.io/assets/sepolia/',
    rpcUrl: 'https://sepolia.infura.io/v3/',
    privateUrl: '',
    explorerUrl: 'https://sepolia.etherscan.io/',
    icon: <EthereumIcon />
  },
  BSC_TESTNET: {
    chainId: 97,
    name: 'bsctestnet',
    display: 'BSC Testnet',
    mainnet: false,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://data-seed-prebsc-2-s2.bnbchain.org:8545',
    privateUrl: '',
    explorerUrl: 'https://testnet.bscscan.com/',
    icon: <BNBIcon />
  },
  POLYGON_MUMBAI: {
    chainId: 80001,
    name: 'polygon-mumbai',
    display: 'Polygon Mumbai',
    mainnet: false,
    available: true,
    addresses: {
      nftAirdropper: '0xE2484eD8accF41C64484596652021DA23F307f47',
      forwarder: '0xD9885063FDbd4c6e0d06De2588AC44E2F333df65',
      factory: '0x489e3Bec6733336f7fd6809DF890258F90750921'
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://polygon-mumbai.infura.io/v3/',
    privateUrl: 'https://rpc-mumbai.maticvigil.com',
    explorerUrl: 'https://mumbai.polygonscan.com/',
    icon: <PolygonIcon />
  },
  AVALANCHE_FUJI: {
    chainId: 43113,
    name: 'avalanche-fuji',
    display: 'Avalanche Fuji',
    mainnet: false,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://avalanche-fuji.infura.io/v3/',
    privateUrl: '',
    explorerUrl: 'https://testnet.snowtrace.io/',
    icon: <AvalancheIcon />
  },
  ARBITRUM_GOERLI: {
    chainId: 421613,
    name: 'arbitrum-goerli',
    display: 'Arbitrum Goerli',
    mainnet: false,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://arbitrum-goerli.infura.io/v3/',
    privateUrl: '',
    explorerUrl: 'https://testnet.arbiscan.io/',
    icon: <ArbitrumIcon />
  },
  OPTIMISM_TESTNET: {
    chainId: 69,
    name: 'optimistic-kovan',
    display: 'Optimism Kovan',
    mainnet: false,
    available: false,
    addresses: {
      nftAirdropper: '',
      forwarder: '',
      factory: ''
    },
    currency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18
    },
    marketplaceUrl: 'https://opensea.io/',
    rpcUrl: 'https://optimism-goerli.infura.io/v3/',
    privateUrl: '',
    explorerUrl: 'https://goerli-optimism.etherscan.io/',
    icon: <OptimismIcon />
  }
};

/**
 * Retrieves the contract addresses for a given chain ID.
 *
 * @param {number} chainId - The ID of the chain.
 * @returns {Object} - An object containing the claimer and forwarder addresses.
 * @throws {Error} - Throws an error if the chain ID is not found.
 */
export const getContractAddressByChainId = (chainId: number): {claimer: string; forwarder: string;} => {
  const chain: IChain | undefined = Object.values(chains).find(chain => chain.chainId === chainId);

  if (!chain) {
    throw new Error(`Chain with ID ${chainId} not found.`);
  }

  return {
    claimer: chain.addresses.nftAirdropper,
    forwarder: chain.addresses.forwarder
  };
};

/**
 * Get the target chain based on the chainId provided.
 *
 * @param {number} chainId - The ID of the chain to target.
 * @returns {Promise<IGetChainTarget | null>} - Returns a Promise that resolves to the target chain or null if not found.
 */
export const getChainTarget = async(chainId: number): Promise<IGetChainTarget | null> => {
  const isMainnet = process.env.REACT_APP_NODE_ENV !== 'development';
  const chain: IChain | undefined = Object.values(chains).find(chain => chain.chainId === chainId && chain.mainnet === isMainnet && chain.available);
  if (!chain) return null;

  return {
    id: '0x' + chain.chainId.toString(16),
    name: chain.display,
    nativeCurrency: {
      name: chain.currency.name,
      symbol: chain.currency.symbol,
      decimals: chain.currency.decimals
    },
    rpcUrl: chain.rpcUrl,
    privateUrl: chain.privateUrl,
    scanLink: chain.explorerUrl
  };
};

// Pasar este array a function que devuelva un array y usar supportedChains
export const networks = () => {
  const convertChain = (chain: IChain) => ({
    available: chain.available,
    value: chain.chainId,
    label: chain.display,
    icon: chain.icon
  });

  const isMainnet = process.env.REACT_APP_NODE_ENV !== 'development';
  return Object.values(chains).filter(chain => (chain.mainnet === isMainnet && chain.available)).map(convertChain);
};

const Web3Utils = {
  switchToChain: async(chainId: number): Promise<Promise<void> | null> => {
    const targetChainId = await getChainTarget(chainId);

    if (!targetChainId) {
      return null;
    }

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{chainId: targetChainId.id}]
      });
    } catch (switchError: any) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{
              chainId: targetChainId.id,
              rpcUrls: [targetChainId.privateUrl],
              chainName: targetChainId.name,
              nativeCurrency: targetChainId.nativeCurrency,
              blockExplorerUrls: [targetChainId.scanLink]
            }]
          });
        } catch (error) {
          throw error;
        }
      }
      throw switchError;
    }
  },

  /**
	 * @function getCurrentAddress():
	 * @description Get the current address used in ethereum instance.
	 */
  getCurrentAddress: async(): Promise<string> => {
    const web3 = new Web3();

    const address = window?.ethereum?.selectedAddress?.toString();
    return web3.utils.toChecksumAddress(address);
  },

  /**
	 * @function getAuthenticationSigning():
	 * @param provider Web3Provider, initialized on redux state management.
	 * @param address User wallet address.
	 */
  getAuthenticationSigning: async(provider: Web3Provider) => {
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const message = `I confirm my address is: ${address}`;
    const signature = await signer.signMessage(message);
    return {
      message,
      signature
    };
  },

  isValidAddress: (address: string) => {
    const web3 = new Web3();
    return web3.utils.toChecksumAddress(address);
  },

  /**
	 * @function shortHexAddress():
	 * @description Short the hexadecimal account string
	 * @param address Address
	 * @param length Cut length
	 */
  shortHexAddress: (address = '', length = 6): string => {
    const len = address.length;
    return `${address.substr(0, length)}...${address.substr(len - 4, len - 1)}`;
  },

  signMessage: async(web3: Web3, method: string, params: object, from: string): Promise<string> => {
    return await new Promise((resolve, reject) => {
      (web3?.currentProvider as any)?.sendAsync(
        {
          method,
          params,
          from
        },
        function(err: any, result: any) {
          if (err) reject(err);
          if (result.error) reject(result.error.message);

          resolve(result.result);
        }
      );
    });
  }
};

export default Web3Utils;
