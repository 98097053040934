export enum ModalsId {
  VERIFY_EMAIL_SIGNUP = 'VERIFY_EMAIL_SIGNUP',
  SEND_NFT = 'SEND_NFT',
  EDIT_NFT_DETAILS = 'EDIT_NFT_DETAILS',
  CONFIRM_MESSAGE = 'CONFIRM_MESSAGE',
  PUBLISHING_COLLECTION = 'PUBLISHING_COLLECTION',
  CLAIMING_AIRDROP_COLLECTION_NFT = 'CLAIMING_AIRDROP_COLLECTION_NFT',
  PUBLISHING_GIFT = 'PUBLISHING_GIFT',
  SIMPLE_MESSAGE = 'SIMPLE_MESSAGE',
  EDIT_PROFILE = 'EDIT_PROFILE',
  CLAIM_REWARD = 'CLAIM_REWARD',
  REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD',
  USER_NAVIGATION_MENU = 'USER_NAVIGATION_MENU',
  CONTACT_SALES = 'CONTACT_SALES',
  CONFIRM_CLAIM_AIRDROP_NFT = 'CONFIRM_CLAIM_AIRDROP_NFT',
  INSTALL_WALLET = 'INSTALL_WALLET',
  IMPORT_NFT = 'IMPORT_NFT',
  START_GIVITNFT = 'START_GIVITNFT',
  COMPLETE_QUEST = 'COMPLETE_QUEST',
  MINT_ON_MOBILE = 'MINT_ON_MOBILE',
  BUY_LICENSE = 'BUY_LICENSE',
  SHARE_SHOW_QR_CODE = 'SHARE_SHOW_QR_CODE',

  // Creator
  EDIT_COLLECTION_LINKS = 'EDIT_COLLECTION_LINKS',
  CREATE_OR_EDIT_NFT = 'CREATE_OR_EDIT_NFT',
  DELETE_NFT = 'DELETE_NFT',
  DELETE_COLLECTION = 'DELETE_COLLECTION',
  SEND_MULTIPLE_NFTS = 'SEND_MULTIPLE_NFTS',
  CREATE_OR_EDIT_QUEST = 'CREATE_OR_EDIT_QUEST',
  CREATOR_WELCOME = 'CREATOR_WELCOME'
}
