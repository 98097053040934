// Dependencies
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// StyleSheet
import styles from './MyCollections.module.scss';

// Collections
import useCollections from '@hooks/useCollections';

// Components
import PageTitle from '@components/PageTitle';
import CollectionCard from '@components/cards/CollectionCard';
import EmptyState from '@components/EmptyState';
import Alert from '@components/Alert';
import {TailSpin} from 'react-loader-spinner';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

// Assets
import {FaUsers} from 'react-icons/fa';

import language_es from 'src/locales/es/pages/user/myCollections.json';
import language_en from 'src/locales/en/pages/user/myCollections.json';

function MyCollections(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {collectionList, fetchCollectionList} = useCollections();
  const navigate = useNavigate();
  const {isLoading} = useLoader(LoadersId.IS_FETCHING_COLLECTION_LIST);

  useEffect(() => {
    fetchCollectionList();
  }, []);

  return (
    <div className={styles.layout}>
      <PageTitle
        text={language.collectionsTitle}
      />
      <Alert
        variant={'message'}
        title={language.uniqueCommunityMessage}
        description={language.questCompletionDescription}
      />

      {isLoading ? (
          <div className={styles.loader}>
            <TailSpin
              width={32}
              height={32}
              color={'#1B2C71'}
            />
          </div>
      ) : (
        collectionList?.length ? (
          <div className={styles.grid}>
            {collectionList?.map((collection, index) => (
              <CollectionCard
                key={`--collection-card-${index.toString()}`}
                onClick={() => navigate(`/collection/${collection.givitUrl}`)}
                description={collection.description}
                bannerUrl={collection.banner}
                name={collection.name}
                published={collection.published}
                creator={{
                  name: collection.creator?.name,
                  photoUrl: collection.creator?.avatar
                }}
              />
            ))}
          </div>
        ) : (
          <div className={styles.empty}>
            <EmptyState
              icon={<FaUsers />}
              text={language.noCollectionsAvailable}
            />
          </div>
        )
      )}
    </div>
  );
}

export default MyCollections;
