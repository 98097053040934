// Dependencies
import {fork, all} from 'redux-saga/effects';

// Sagas
import auth from './auth/sagas';
import nft from './nft/sagas';
import collection from './collection/sagas';
import reward from './reward/sagas';
import gift from './gift/sagas';
import quest from './quest/sagas';
import license from './license/sagas';

// Forked Sagas
export const sagas = [
  auth,
  nft,
  collection,
  reward,
  gift,
  quest,
  license
];

export default function * rootSaga(): any {
  yield all(sagas.map(fork));
}
