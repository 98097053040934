// Dependencies
import React, {useCallback, useEffect} from 'react';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';

// StyleSheet
import styles from './Header.module.scss';

// Components
import UserNavigation from '@components/UserNavigation';
import NavItem, {NavItemProps} from './partials/NavItem';
import IconButton from '@components/IconButton';

// Assets
import Logo from '@assets/images/logo.png';
import {RiMenuLine} from 'react-icons/ri';

// Hooks
import useAuth from '@hooks/useAuth';
import useResponsive from '@hooks/useResponsive';
import useModal, {ModalsId} from '@hooks/useModal';

// Utils
import {isActivePath} from '@utils/url';
import {getMenuItemsWithBadge} from '@config/index';

// Import languages
import language_es from 'src/locales/es/components/header.json';
import language_en from 'src/locales/en/components/header.json';

function Header(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {user} = useAuth();
  const {pathname} = useLocation();
  const {isMobile, isTabletVertical} = useResponsive();
  const {openModal, closeModal, modal} = useModal();

  const items: NavItemProps[] = getMenuItemsWithBadge(
    user?.counts?.userNFTs ?? 0,
    user?.counts?.userRewards ?? 0,
    user?.counts?.userCollections ?? 0
  );

  /**
   * Handles the action to open the user navigation menu.
   */
  const handleOpenMenu = useCallback(() => {
    openModal(ModalsId.USER_NAVIGATION_MENU);
  }, []);

  useEffect(() => {
    if ((!isTabletVertical && !isMobile) && modal && modal.id === ModalsId.USER_NAVIGATION_MENU) {
      closeModal();
    }
  }, [isTabletVertical, isMobile, modal]);

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.container}>
          <div className={styles.side}>
            <Link to={'/'}>
              <img src={Logo} alt={'GivitNFT'} width={154} height={40} />
            </Link>
            {(user && !(isTabletVertical || isMobile)) && (
              <nav className={styles.navigation}>
                {items.map((item, index: number) => (
                  <NavItem
                    key={`--menu-item-key-${index.toString()}`}
                    icon={item.icon}
                    text={language[item.text as keyof typeof language]}
                    quantity={item.quantity}
                    active={isActivePath(pathname, item.path) }
                    path={item.path}
                  />
                ))}
              </nav>
            )}
          </div>
          <div className={styles.side}>
            {isTabletVertical || isMobile ? (
              <IconButton variant={'ghost'} icon={<RiMenuLine />} onClick={handleOpenMenu} />
            ) : (
              <UserNavigation />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
