// Dependencies
import React, {useCallback, useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

// Forms
import CreateOrEditRewardForm, {FormValues} from '@forms/CreateOrEditRewardForm';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';
import useGifts from '@hooks/useGifts';

// Types
import {rewardContentTypes, rewardTypes} from '@config/index';

import language_es from 'src/locales/es/forms/createOrEditRewardForm.json';
import language_en from 'src/locales/en/forms/createOrEditRewardForm.json';

function CreateOrEditReward(): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {id, rewardId} = useParams<{id: string, rewardId: string}>();
  const {gift, createGift, editGift, fetchGiftById} = useGifts(Number(rewardId));
  const {isLoading: isLoadingCreateReward} = useLoader(LoadersId.IS_CREATING_GIFT);
  const {isLoading: isLoadingEditReward} = useLoader(LoadersId.IS_EDITING_GIFT);
  const navigate = useNavigate();

  useEffect(() => {
    if (Number(rewardId)) {
      fetchGiftById(Number(rewardId));
    }
  }, []);

  /**
   * Callback function to handle the creation of a new reward (gift) associated with a collection.
   *
   * When invoked, this function creates a new reward (gift) using the provided form values and the collection ID (`id`).
   * The function performs validation checks on the form values to ensure all required fields are provided, including logo, banner, title, description, URL, date, rewardTitle, rewardDescription, and rewardContentTypeId.
   * If all required fields are available and the logo and banner are instances of `File`, the function calls the `createGift` function with the collection ID and the new gift data.
   *
   * @function
   * @name handleCreateOrEditReward
   * @param {FormValues} formValues - The form values representing the new reward (gift) data.
   * @returns {void}
   */
  const handleCreateOrEditReward = useCallback((values: FormValues) => {
    if (!rewardId) {
      createGift(Number(id), values);
    } else {
      editGift(Number(rewardId), values);
    }
  }, [rewardId]);

  const handleCancelCreateOrEditReward = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <CreateOrEditRewardForm
      {...(gift && ({
        initialValues: {
          rewardPhoto: gift.logo,
          rewardBanner: gift.banner,
          rewardTitle: gift.title,
          rewardUrl: gift.url,
          rewardDescription: gift.description,
          rewardType: {
            value: rewardTypes.filter(e => e.value === gift.rewardTypeId)[0].value,
            label: language[rewardTypes.filter(e => e.value === gift.rewardTypeId)[0].label as keyof typeof language]
          },
          rewardContentType: {
            value: rewardContentTypes.filter(e => e.value === gift.rewardContentTypeId)[0].value,
            label: language[rewardContentTypes.filter(e => e.value === gift.rewardContentTypeId)[0].label as keyof typeof language]
          },
          rewardContent: gift.rewardContent,
          rewardDate: gift.date,
          rewardUnique: gift.isUnique !== undefined ? gift.isUnique : false,
          claimingTitle: gift.rewardTitle,
          claimingInstructions: gift.rewardDescription
        }
      }))}
      onSubmit={handleCreateOrEditReward}
      onCancel={handleCancelCreateOrEditReward}
      isLoading={isLoadingCreateReward || isLoadingEditReward}
    />
  );
}

export default CreateOrEditReward;
