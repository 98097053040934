// Dependencies
import React, {useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'clsx';

// Types
import {ClaimRewardProps} from './types';

// StyleSheet
import modalStyle from '../../modals.module.scss';
import styles from './ClaimRewardModal.module.scss';

// Assets
import {RiDownloadCloud2Line, RiFileCopy2Fill, RiGift2Line} from 'react-icons/ri';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import FeaturedIcon from '@components/FeaturedIcon';
import IconButton from '@components/IconButton';
import QuillTextInput from '@components/QuillTextInput';

// PDF
import {Document, Page, pdfjs} from 'react-pdf';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Config
import {IMAGE_UPLOAD_FORMATS, VIDEO_UPLOAD_FORMATS} from '@config/index';

// Hooks
import useModal from '@hooks/useModal';
import Loader from '@components/Loader';

import language_es from 'src/locales/es/modals/claimReward.json';
import language_en from 'src/locales/en/modals/claimReward.json';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function ClaimRewardModal({reward}: ClaimRewardProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const videoRef = useRef<HTMLVideoElement>(null);
  const {closeModal} = useModal();
  const [imageTypeId, setImageTypeId] = useState<number>(0);

  useEffect(() => {
    const getFileType = () => {
      if (reward?.rewards[0]) {
        const extension = '.' + (reward.rewards[0].split('.').pop()?.toLowerCase() as string);

        if (VIDEO_UPLOAD_FORMATS.includes(extension)) setImageTypeId(2);
        else if (IMAGE_UPLOAD_FORMATS.includes(extension)) setImageTypeId(1);
        else setImageTypeId(3);
      }
    };

    getFileType();
  }, [reward?.rewards[0]]);

  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      void videoRef.current.play();
    }
  }, [videoRef]);

  const handlePauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      void videoRef.current.pause();
    }
  }, [videoRef]);

  /**
   * Closes the modal.
   */
  const handleClickClose = useCallback(() => {
    closeModal();
  }, []);

  /**
   * Copies the code from the reward to the clipboard.
   */
  const handleClickCopyCode = useCallback(() => {
    const textarea = document.createElement('textarea');
    textarea.value = reward.rewards[0];

    document.body.appendChild(textarea);

    textarea.select();
    textarea.setSelectionRange(0, textarea.value.length);

    document.execCommand('copy');
    document.body.removeChild(textarea);
  }, []);

  /**
   * Opens the URL of the reward in a new window.
   */
  const handleClickOpenUrl = useCallback(() => {
    window.open(reward?.rewards[0]);
  }, []);

  const downloadImage = useCallback(() => {
    const link = document.createElement('a');
    link.href = reward?.rewards[0];
    link.click();
  }, []);

  const isURL = reward.rewardContentTypeId === 2;
  const isMultimedia = reward.rewardContentTypeId === 3;

  if (!reward) return <Loader />;

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <FeaturedIcon variant={'green'} icon={<RiGift2Line />} />

      <div className={styles.field}>
        <Typography variant={'text'} size={'lg'} weight={'semiBold'}>{reward.rewardTitle}</Typography>
        <QuillTextInput
          theme='snow'
          value={reward.rewardDescription}
          readOnly={true}
          modules={{toolbar: false}}
        />
      </div>

      <div className={styles.field}>
        {!isMultimedia ? (
          <>
            <div className={styles.code}>
              <Typography variant={'text'} size={'sm'} weight={'regular'}>{reward?.rewards[0]}</Typography>
              <IconButton
                variant={'ghost'}
                onClick={isURL ? handleClickOpenUrl : handleClickCopyCode}
                icon={isURL ? <RiFileCopy2Fill /> : <RiFileCopy2Fill />}
              />
            </div>
          </>
        ) : (
          <div onMouseLeave={handlePauseVideo} onMouseEnter={handlePlayVideo} className={styles.image}>
            {imageTypeId === 2
              ? <video key={reward.rewards[0]} ref={videoRef} loop muted autoPlay={false} height={'100%'} width={'100%'}><source src={reward.rewards[0]} /></video>
              : imageTypeId === 1 ? <img alt={reward.title} src={reward.rewards[0]} />
                : <div className={styles.pdf}><Document file={reward.rewards[0]}><Page pageNumber={1} /></Document></div>
            }
            <div className={styles.actions}>
              <Button
                variant={'solid'}
                color={'tertiary'}
                onClick={downloadImage}
                iconRight={<RiDownloadCloud2Line />}>
                {language.downloadButton}
              </Button>
            </div>
          </div>
        )}
      </div>

      <div className={classNames([styles.field, styles.footer])}>
        <div>
          <Button
            variant={'solid'}
            color={'primary'}
            onClick={handleClickClose}>
            {language.closeButton}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ClaimRewardModal;
