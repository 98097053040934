// Dependencies
import React from 'react';

// Types
import {RadioInputProps} from './types';

// StyleSheet
import styles from './RadioInput.module.scss';

// Components
import Typography from '@components/Typography';

const RadioInput = ({
  label,
  description,
  defaultChecked = false,
  checked = false,
  onChange
}: RadioInputProps): React.ReactElement => (
  <div className={styles.layout}>
    <div className={styles.input}>
      <input type={'radio'} defaultChecked={defaultChecked} checked={checked} onChange={onChange} />
      <label htmlFor={label} className={styles.label}>{label}</label>
    </div>
    {description && (
      <div className={styles.description}>
        <Typography variant={'text'} weight={'regular'} size={'sm'}>
          {description}
        </Typography>
      </div>
    )}
  </div>
);

export default RadioInput;
