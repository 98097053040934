// Dependencies
import React from 'react';

// Components
import Typography from '@components/Typography';
import QuillTextInput from '@components/QuillTextInput';

// Styles
import styles from './GiftCard.module.scss';

// Types
import {GiftCardProps} from './types';

function GiftCard({onClick, ...gift}: GiftCardProps): React.ReactElement {
  return (
    <div className={styles.layout} onClick={onClick}>
      <div className={styles.field}>
        <div className={styles.title}>
          <Typography variant={'display'} size={'xs'} weight={'bold'}>{gift.title}</Typography>
        </div>
      </div>
      <div className={styles.field}>
        <div className={styles.picture}>
          <img alt={gift.title} src={gift.banner} width={'100%'} height={100} />
        </div>
      </div>
      <div className={styles.field}>
        <Typography variant={'text'} size={'md'} weight={'regular'}>
          <QuillTextInput
            theme='snow'
            value={gift?.description}
            readOnly={true}
            modules={{toolbar: false}}
          />
        </Typography>
      </div>
    </div>
  );
}
export default GiftCard;
