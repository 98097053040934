// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {ShowQRCodeModalProps} from './types';

// Components
import FeaturedIcon from '@components/FeaturedIcon';
import Button from '@components/Button';
import Typography from '@components/Typography';
import QRCode from 'react-qr-code';

// StyleSheet
import styles from './ShowQRCodeModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Hooks
import useModal from '@hooks/useModal';

// Assets
import {RiShareFill} from 'react-icons/ri';

import language_es from 'src/locales/es/modals/showQrCodeModal.json';
import language_en from 'src/locales/en/modals/showQrCodeModal.json';

function ShowQRCodeModal({collection, action}: ShowQRCodeModalProps): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.text}><FeaturedIcon variant={'green'} icon={(<RiShareFill />)} /></div>
      <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.title.replace('{{name}}', collection.name)}</Typography>
      <div className={styles.container}>
        <div style={{height: 'auto', margin: '0 auto', maxWidth: 192, width: '100%'}}>
          <QRCode
            style={{height: 'auto', maxWidth: '100%', width: '100%'}}
            value={window.location.href}
          />
        </div>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} color={'primary'} onClick={closeModal}>
              {language.closeButton}
            </Button>
            <Button variant={'solid'} color={'primary'} onClick={action}>
              {language.copyLinkButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowQRCodeModal;
