// Dependencies
import React, {useCallback, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// Styles
import styles from './CreatorDetails.module.scss';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import CollectionCard from '@components/cards/CollectionCard';
import PageTitle from '@components/PageTitle';
import FeaturedIcon from '@components/FeaturedIcon';

// Assets
import {RiAddLine, RiSearchLine} from 'react-icons/ri';

// Types
import {ICollection} from '@type/collection';

// Hooks
import useAuth from '@hooks/useAuth';
import useModal, {ModalsId} from '@hooks/useModal';

import language_es from 'src/locales/es/pages/creator/creatorDetails.json';
import language_en from 'src/locales/en/pages/creator/creatorDetails.json';

function CreatorDetails(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {user} = useAuth();
  const navigate = useNavigate();
  const {openModal} = useModal();

  useEffect(() => {
    if (user?.creator?.banner === '' && user.creator.avatar === '') {
      openModal(ModalsId.CREATOR_WELCOME, {
        action: handleClickEditProfile
      });
    }
  }, []);

  /**
   * Callback function to handle clicking on a collection.
   *
   * @function
   * @name handleClickCollection
   * @param {Object} collection - The collection object of type ICollection.
   * @returns {void}
   */
  const handleClickCollection = useCallback((collection: ICollection) => {
    navigate(`/creator/collection/${collection.id}`);
  }, [navigate]);

  /**
   * Callback function to handle clicking the "New Collection" button.
   *
   * @function
   * @name handleClickNewCollection
   * @returns {void}
   */
  const handleClickNewCollection = useCallback(() => {
    navigate('/creator/new-collection');
  }, [navigate]);

  /**
   * Callback function to handle clicking the "Edit Profile" button.
   * Opens the Edit Profile modal.
   *
   * @function
   * @name handleClickEditProfile
   * @returns {void}
   */
  const handleClickEditProfile = useCallback(() => {
    if (user?.creator) {
      openModal(ModalsId.EDIT_PROFILE, {
        initialValues: {
          name: user.creator.name,
          url: user.creator.url,
          avatar: user.creator.avatar,
          banner: user.creator.banner
        }
      });
    }
  }, []);

  return (
    <div>
      <div className={styles.banner}>
        <img src={user?.creator?.banner} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <img src={user?.creator?.avatar} />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h4'} weight={'semiBold'}>{user?.creator?.name ? user?.creator?.name : language.defaultCreatorName}</Typography>
            <Typography variant={'text'} size={'sm'} component={'span'} weight={'semiBold'}>
              {user?.creator?.url}
            </Typography>
          </div>
        </div>
        <div>
          <Button onClick={handleClickEditProfile}>{language.editProfile}</Button>
        </div>
      </div>
      <div className={styles.container}>
        <PageTitle
          text={language.myCollections}
          rightElement={(
            <div>
              <Button
                onClick={handleClickNewCollection}
                iconLeft={(
                  <RiAddLine />
                )}>
                {language.newCollection}
              </Button>
            </div>
          )}
        />
        {user?.creator?.collections.length ? (
          <div className={styles.grid}>
            {user?.creator?.collections?.map((collection, index) => (
              <CollectionCard
                key={`--collection-card-${index.toString()}`}
                onClick={() => handleClickCollection(collection)}
                name={collection.name}
                bannerUrl={collection.banner}
                description={collection.description}
                published={collection.published}
              />
            ))}
          </div>
        ) : (
          <div className={styles.empty}>
            <FeaturedIcon
              icon={(
                <RiSearchLine />
              )}
            />
            <div className={styles.row}>
              <Typography variant={'text'} weight={'semiBold'} size={'xl'} textAlign={'center'}>{language.startByCreatingCollection}</Typography>
              <Typography variant={'text'} weight={'regular'} size={'md'} textAlign={'center'}>{language.yourCollectionsWillLiveHere}</Typography>
              <div className={styles.actions}>
                <div>
                  <Button
                    onClick={handleClickNewCollection}
                    iconLeft={(
                      <RiAddLine />
                    )}>
                    {language.newCollection}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default CreatorDetails;
