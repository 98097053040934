// Dependencies
import React from 'react';
import classNames from 'clsx';

// Types
import {MintOnMobileModalProps} from './types';

// Components
import FeaturedIcon from '@components/FeaturedIcon';
import Button from '@components/Button';
import Typography from '@components/Typography';

// StyleSheet
import styles from './MintOnMobileModal.module.scss';
import modalStyle from '../../modals.module.scss';

// Hooks
import useModal from '@hooks/useModal';

// Assets
import {RiAlertFill} from 'react-icons/ri';

import language_es from 'src/locales/es/modals/mintOnMobile.json';
import language_en from 'src/locales/en/modals/mintOnMobile.json';

function MintOnMobileModal({
  action = undefined
}: MintOnMobileModalProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  return (
    <div className={classNames([modalStyle.modal, styles.layout])}>
      <div className={styles.text}><FeaturedIcon variant={'yellow'} icon={(<RiAlertFill />)} /></div>
      <Typography component={'h1'} variant={'text'} textAlign={'center'} size={'lg'} weight={'semiBold'}>{language.mobileDetected}</Typography>
      <div className={styles.container}>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>{language.mintRedirect}</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>1. <b>{language.installMetamaskTitle}</b> If you don&#39;t have Metamask installed on your Mobile, go to <a href='https://metamask.io' rel='noreferrer' target='_blank'>https://metamask.io</a> to install it.</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>2. <b>{language.tryTwiceTitle}</b> If first time you open Metamsk Mobile it stops and don&#39;t redirect to GivitNFT, go to your brower and click the button &#39;Mint as NFT&#39; again.</Typography>
        <Typography component={'p'} variant={'text'} textAlign={'left'} size={'sm'}>{language.desktopRecommendation}</Typography>
        <div className={styles.footer}>
          <div className={styles.actions}>
            <Button type={'button'} variant={'outline'} color={'primary'} onClick={closeModal}>
              {language.notNow}
            </Button>
            <Button variant={'solid'} color={'primary'} onClick={action}>
              {language.mintMetamaskMobile}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MintOnMobileModal;
