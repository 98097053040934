// Dependencies
import React, {useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom';

// Styles
import styles from './GiftDetails.module.scss';

// Hooks
import useGifts from '@hooks/useGifts';
import useRewards from '@hooks/useRewards';
import useAuth from '@hooks/useAuth';
import useLoader, {LoadersId} from '@hooks/useLoader';

// Components
import Typography from '@components/Typography';
import Button from '@components/Button';
import QuillTextInput from '@components/QuillTextInput';

// Utils
import {format} from 'date-fns';
import Loader from '@components/Loader';

import language_es from 'src/locales/es/pages/user/giftDetails.json';
import language_en from 'src/locales/en/pages/user/giftDetails.json';

function GiftDetails(): React.ReactElement | null {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const params = useParams();

  const {gift, fetchGiftById} = useGifts(Number(params.id));
  const {fetchRewards} = useRewards(Number(params.id));
  const {user} = useAuth();
  const {isLoading: isLoadingFetchingGiftList} = useLoader(LoadersId.IS_FETCHING_GIFT_LIST);
  const {isLoading: isLoadingRewards} = useLoader(LoadersId.IS_FETCHING_REWARDS);
  let buttonGetRewardsMessage = '';

  useEffect(() => {
    if (!gift) fetchGiftById(Number(params.id));
  }, []);

  /**
   * @function handlePressRewards
   */
  const handlePressRewards = useCallback(() => {
    fetchRewards('user', Number(params.id));
  }, []);

  const getRewardTypeTitle = (rewardTypeId: number) => {
    if (rewardTypeId === 1) return language.event;
    else if (rewardTypeId === 2) return language.gift;
    else return '';
  };

  const getRewardContentTypeTitle = (rewardContentTypeId: number) => {
    if (rewardContentTypeId === 1) { buttonGetRewardsMessage = language.getYourCode; return getRewardTypeTitle(gift.rewardTypeId) + ' code'; }
    if (rewardContentTypeId === 2) { buttonGetRewardsMessage = language.getTheLink; return getRewardTypeTitle(gift.rewardTypeId) + ' link'; }
    if (rewardContentTypeId === 3) { buttonGetRewardsMessage = language.enjoyTheMultimedia; return 'Multimedia ' + getRewardTypeTitle(gift.rewardTypeId); }
  };

  const handleRewardPreview = useCallback(() => {
    fetchRewards('creator', Number(params.id));
  }, []);

  if (isLoadingFetchingGiftList || !gift) {
    return <Loader />;
  }

  return (
    <div>
      <div className={styles.banner}>
        <img src={gift?.banner} alt={gift?.title} width={'100%'} height={'100%'} />
      </div>
      <div className={styles.name}>
        <div className={styles.avatar}>
          <img src={gift?.logo} alt={gift?.title} />
        </div>
        <div className={styles.info}>
          <div className={styles.row}>
            <Typography variant={'display'} size={'sm'} component={'h4'} weight={'semiBold'}>{gift?.title}</Typography>
            {gift?.url && (<Typography variant={'text'} className={styles.value} size={'sm'} component={'span'} weight={'semiBold'}><a href={gift.url} rel='noreferrer' target='_blank'>{gift.url}</a></Typography>)}
            <div className={styles.textinline}>
              <img width={'24px'} height={'24px'} src={gift.collection.creator.avatar} />
              <Typography variant={'text'} className={styles.value} size={'md'} weight={'regular'}>{gift.collection.creator.name}</Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.grid}>
          <div className={styles.field}>
            <div className={styles.row}>
              <Typography variant={'text'} className={styles.label} size={'md'} weight={'bold'}>{gift.rewardTypeId === 2 ? language.giftAvailableFrom : language.eventDate}</Typography>
              <Typography variant={'text'} className={styles.value} size={'md'} weight={'regular'}>{gift.date && format(Date.parse(gift.date), 'dd/MM/yyyy HH:mm')}</Typography>
            </div>

            <div className={styles.row}>
              <Typography variant={'text'} className={styles.label} size={'md'} weight={'bold'}>{gift.rewardTypeId === 2 ? language.giftDescription : language.eventDescription}</Typography>
              <QuillTextInput
                theme='snow'
                value={gift.description}
                readOnly={true}
                modules={{toolbar: false}}
              />
            </div>

          </div>
          <div className={styles.field}>
            <div className={styles.reward}>
              <Typography component={'h1'} variant={'display'} size={'xs'} weight={'bold'}>{gift.rewardTypeId === 2 ? 'Gift access' : 'Event access'}</Typography>
              <Typography component={'span'} variant={'text'} size={'md'} weight={'regular'}>{language.accessRequirement.replace('{{collectionName}}', gift.collection.name)}</Typography>
              <Typography component={'span'} variant={'text'} size={'md'} weight={'regular'}>{language.nftOwnershipRequirement.replace('{{collectionName}}', gift.collection.name).replace('{{rewardContentType}}', getRewardContentTypeTitle(gift.rewardContentTypeId) as string)}</Typography>
              <Button
                size={'md'}
                variant={'solid'}
                onClick={handlePressRewards}
                isLoading={isLoadingRewards}
                color={'secondary'}>
                {buttonGetRewardsMessage}
              </Button>
            </div>
            {user?.creator?.id === gift.collection.creator.id &&
            <div className={styles.row}>
              <Button
                size={'md'}
                variant={'solid'}
                onClick={handleRewardPreview}
                color={'primary'}>
                {language.viewOrDownloadRewardAsCreator}
              </Button>
            </div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GiftDetails;
