// Dependencies
import React, {useCallback} from 'react';
import {FormikProps, useFormik} from 'formik';
import classNames from 'clsx';

// Types
import {CreateNFTFormProps, FormValues} from './types';

// Hooks
import useForm from '@hooks/useForm';
import useModal from '@hooks/useModal';

// Validation
import CreateNFTValidation from './validations';

// Components
import ImageUpload from '@components/ImageUpload';
import TextInput from '@components/TextInput';
import Button from '@components/Button';
import CheckInput from '@components/CheckInput';
import Typography from '@components/Typography';

// StyleSheet
import styles from './CreateOrEditNFTForm.module.scss';

import language_es from 'src/locales/es/forms/createOrEditNFTForm.json';
import language_en from 'src/locales/en/forms/createOrEditNFTForm.json';

const initialDefaultValues: FormValues = {
  image: undefined,
  imageTypeId: 0,
  name: '',
  description: '',
  copies: 0,
  airdrop: false,
  freeCopies: 0,
  quests: false,
  questCopies: 0
};

function CreateOrEditNFTForm({initialValues = initialDefaultValues, isLoading, onSubmit}: CreateNFTFormProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {closeModal} = useModal();

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
	  initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: CreateNFTValidation,
    onSubmit
  });

  const {handleChangeField, getErrorFromField} = useForm<FormValues>(formik);

  const isEditing = initialValues !== initialDefaultValues;

  /**
   * Callback function to handle dropping photos into a field.
   *
   * @function
   * @name handleDropPhoto
   * @param {string} field - The name of the field where the photos are dropped.
   * @param {File[]} photos - An array of File objects representing the dropped photos.
   * @returns {void}
   */
  const handleDropPhoto = useCallback((field: keyof FormValues, photos: File[]) => {
    if (photos.length === 1) {
      const file = photos[0];
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = function(e) {
        const photo = Object.assign(photos[0], {
          preview: URL.createObjectURL(file)
        });
        handleChangeField(field, photo);
        handleChangeField('imageTypeId', photo.type.startsWith('video/') ? 2 : 1);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} className={styles.form}>
      <ImageUpload
        onDrop={(photos) => handleDropPhoto('image', photos)}
        photo={formik.values.image}
      />
      <div className={styles.field}>
        <TextInput
          name={'name'}
          value={formik.values.name}
          placeholder={language.namePlaceholder}
          onChangeText={(name) => handleChangeField('name', name)}
          error={getErrorFromField('name')}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          name={'description'}
          placeholder={language.descriptionPlaceholder}
          value={formik.values.description}
          textarea={true}
          onChangeText={(description) => handleChangeField('description', description)}
        />
      </div>
      <div className={styles.field}>
        <TextInput
          name={'copies'}
          label={language.totalCopiesLabel}
          value={formik.values.copies}
          type={'number'}
          onChangeText={(copies) => handleChangeField('copies', copies)}
          error={getErrorFromField('copies')}
        />
      </div>

      {initialValues.quests ? (<div className={classNames([styles.field, styles.inline])}>
        <CheckInput
          label={language.completeQuestsLabel}
          description={language.completeQuestsDescription}
          checked={formik.values.quests as boolean}
          disabled={false}
          onChange={() => {
            handleChangeField('quests', !formik.values.quests);
          }}
        />
        <TextInput
          name={'questCopies'}
          value={formik.values.questCopies}
          type={'number'}
          disabled={!formik.values.quests}
          onChangeText={(questCopies) => handleChangeField('questCopies', questCopies)}
          error={getErrorFromField('questCopies')}
        />
      </div>
      ) : (<div className={classNames([styles.field, styles.inline])}>
        <CheckInput
          label={language.publicAirdropLabel}
          description={language.publicAirdropDescription}
          checked={formik.values.airdrop as boolean}
          disabled={false}
          onChange={() => {
            handleChangeField('airdrop', !formik.values.airdrop);
          }} />
        <TextInput
          name={'freeCopies'}
          value={formik.values.freeCopies}
          type={'number'}
          disabled={!formik.values.airdrop}
          onChangeText={(freeCopies) => handleChangeField('freeCopies', freeCopies)}
          error={getErrorFromField('freeCopies')}
        />
      </div>)}

      {initialValues.quests ? (
        <Typography variant={'text'} size={'sm'}>{language.availableNFTs.replace('{{copies}}', (formik.values.copies - formik.values.questCopies).toString())}</Typography>) : (
        <Typography variant={'text'} size={'sm'}>{language.availableNFTs.replace('{{copies}}', (formik.values.copies - formik.values.freeCopies).toString())}</Typography>)
      }

      <div className={styles.actions}>
        <Button type={'button'} variant={'outline'} color={'primary'} size={'lg'} onClick={closeModal}>{language.cancelButton}</Button>
        <Button type={'submit'} variant={'solid'} color={'primary'} size={'lg'} isLoading={isLoading}>{isEditing ? language.editNftButton : language.createNftButton}</Button>
      </div>
    </form>
  );
}

export type {FormValues};

export default CreateOrEditNFTForm;
