// Dependencies
import React, {useCallback, useMemo} from 'react';
import {Link} from 'react-router-dom';

// Components
import Typography from '@components/Typography';
import IconButton from '@components/IconButton';
import Badge from '@components/Badge';
import Button from '@components/Button';
import NFTViewer from '@components/NFTViewer';

// Styles
import styles from './NFTCard.module.scss';

// Hooks
import useLoader, {LoadersId} from '@hooks/useLoader';

// Types
import {NFTCardProps} from './types';
import {NFTState} from '@type/nft';

// Assets
import {RiSendPlane2Line} from 'react-icons/ri';
import {FaUsers} from 'react-icons/fa';

import language_es from 'src/locales/es/components/cards/nftCard.json';
import language_en from 'src/locales/en/components/cards/nftCard.json';

function NFTCard({onClaim, onSend, onOpenMetamask, onClick, disabled, isInstalled, isMobile, ...nft}: NFTCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const {isLoading: isLoadingSending} = useLoader(LoadersId.IS_SENDING_NFT);

  const stateButtonText: {[key: string]: number | string} = {
    [NFTState.READY_TO_CLAIM]: language.mintAsNFT,
    [NFTState.CLAIMED]: language.nftMinted,
    [NFTState.RECENTLY_CLAIMED]: language.nftMinted,
    [NFTState.PROCESSING]: language.signTheMessage,
    [NFTState.IS_WAITING_CONNECT]: language.connectYourWallet,
    [NFTState.IS_WAITING_CHANGE_CHAIN]: language.switchNetwork,
    [NFTState.IS_WAITING_TRANSACTION]: language.validatingTransaction,
    [NFTState.IS_WAITING_CONFIRMATION]: language.mintAsNFT
  };

  /**
   * @function handlePressClaimButton()
   * @description Handle press claim button.
   */
  const handlePressClaimButton = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClaim(nft);
  }, [nft]);

  /**
   * @function handlePressSendNFT
   */
  const handlePressSendNFT = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onSend(nft);
  }, [nft]);

  /**
   * @function handlePressOpenMetamask
   */
  const handlePressOpenMetamask = useCallback((e: React.PointerEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpenMetamask();
  }, []);

  const isLoading = useMemo(() =>
    nft.state === NFTState.PROCESSING ||
    nft.state === NFTState.IS_WAITING_CONNECT ||
    nft.state === NFTState.IS_WAITING_TRANSACTION ||
    nft.state === NFTState.IS_WAITING_CHANGE_CHAIN,
  [nft.state]);

  return (
    <div className={styles.layout}>
      {/* Header */}
      <div>
        <div className={styles.field}>
          <Link to={`/collection/${nft.collectionGivitUrl}`} className={styles.link}>
            <Badge
              className={'collectionTitle'}
              color={'blue'}
              variant={'outline'}
              size={'sm'}
              iconRight={(
                <FaUsers />
              )}
              label={nft.collectionName}
            />
          </Link>
        </div>
        <div className={styles.field}>
          <div className={styles.row}>
            <div className={styles.title}>
              <Typography variant={'display'} size={'xs'} weight={'bold'}>{nft.name}</Typography>
            </div>
          </div>
          <div className={styles.row}>
            {!nft.tx && (
              <IconButton
                icon={(<RiSendPlane2Line />)}
                onClick={handlePressSendNFT}
                isLoading={isLoadingSending}
                disabled={isLoading}
              />
            )}
          </div>
        </div>
      </div>
      <NFTViewer
        onClick={onClick}
        isMobile={isMobile}
        mediaTypeId={nft.imageTypeId}
        mediaSrc={nft.image}
      />
      <div className={styles.field}>
        <div className={styles.row}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.creator}</Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>{nft.creator}</Typography>
        </div>
        <div className={styles.row}>
          <Typography component={'p'} className={styles.label} variant={'text'} size={'xs'} weight={'regular'}>{language.serie}</Typography>
          <Typography component={'p'} className={styles.value} variant={'text'} size={'xs'} weight={'bold'}>#{nft.smartContractNFTId}</Typography>
        </div>
      </div>
      <div className={styles.field}>
        {!isInstalled && isMobile && !nft.tx ? (
          <Button
            className={styles.claimNFTButton}
            onClick={handlePressOpenMetamask}
            isLoading={isLoading}
            showTextOnLoading={true}>
            {language.mintAsNFT}
          </Button>
        ) : (
          <Button
            className={styles.claimNFTButton}
            onClick={handlePressClaimButton}
            isLoading={isLoading}
            showTextOnLoading={true}
            disabled={(nft.state === NFTState.CLAIMED || nft.state === NFTState.RECENTLY_CLAIMED)}>
            {stateButtonText[nft.state] as string}
          </Button>
        )}
      </div>
    </div>
  );
}
export default NFTCard;
