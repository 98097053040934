// Types
import {
  SetGiftListAction,
  SetGiftListCollectionAction,
  FetchGiftListAction,
  FetchGiftByIdAction,
  FetchGiftListByCollectionIdAction,
  CreateGiftAction,
  CreateGiftActionProps,
  EditGiftAction,
  EditGiftActionProps,
  DeleteGiftAction,
  PublishGiftAction,
  giftActionsTypes,
  SetGiftDataAction
} from './types';

import {IGift} from '@type/gift';

/**
 * Creates an action to fetch the list of gifts.
 * @function
 * @param {boolean} onlyPublished - Whether to fetch only the published gifts.
 * @returns {FetchGiftListAction} The action object with the type and payload.
 */
export function fetchGiftList(onlyPublished: Boolean): FetchGiftListAction {
  return {
    type: giftActionsTypes.FETCH_GIFT_LIST,
    payload: {
      onlyPublished
    }
  };
}

/**
 * Creates an action to fetch a gift by ID.
 * @function
 * @param {number} giftId - The ID of the gift to be fetched.
 * @returns {FetchGiftByIdAction} The action object with the type and payload.
 */
export function fetchGiftById(giftId: number): FetchGiftByIdAction {
  return {
    type: giftActionsTypes.FETCH_GIFT_BY_ID,
    payload: {
      id: giftId
    }
  };
}

/**
 * Creates an action to fetch the list of gifts by collection ID.
 * @function
 * @param {number} collectionId - The ID of the collection for which to fetch the list of gifts.
 * @param {boolean} onlyPublished - Whether to fetch only the published gifts.
 * @returns {FetchGiftListByCollectionIdAction} The action object with the type and payload.
 */
export function fetchGiftListByCollectionId(collectionId: number, onlyPublished: Boolean): FetchGiftListByCollectionIdAction {
  return {
    type: giftActionsTypes.FETCH_GIFT_LIST_BY_COLLECTION_ID,
    payload: {
      collectionId,
      onlyPublished
    }
  };
}

/**
 * Creates an action to set the list of gifts.
 * @function
 * @param {Object} [list] - An optional object where the keys are gift IDs and the values are the gift objects.
 * @returns {SetGiftListAction} The action object with the type and payload.
 */
export function setGiftList(list?: {[key: string]: IGift}): SetGiftListAction {
  return {
    type: giftActionsTypes.SET_GIFT_LIST,
    payload: {
      list
    }
  };
}

/**
 * Creates an action to set the list of gifts in a collection.
 * @function
 * @param {Object} [list] - An optional object where the keys are gift IDs and the values are the gift objects.
 * @returns {SetGiftListCollectionAction} The action object with the type and payload.
 */
export function setGiftListCollection(list?: {[key: string]: IGift}): SetGiftListCollectionAction {
  return {
    type: giftActionsTypes.SET_GIFT_LIST_COLLECTION,
    payload: {
      list
    }
  };
}

/**
 * Creates an action to set the data of a gift.
 * @function
 * @param {number} giftId - The ID of the gift whose data should be set.
 * @param {IGift} data - The data to be set for the gift.
 * @returns {SetGiftDataAction} The action object with the type and payload.
 */
export function setGiftData(giftId: number, data: IGift): SetGiftDataAction {
  return {
    type: giftActionsTypes.SET_GIFT_DATA,
    payload: {
      giftId,
      data
    }
  };
}

/**
 * Creates an action to create a new gift.
 * @function
 * @param {number} collectionId - The ID of the collection to which the gift should be added.
 * @param {CreateGiftActionProps} giftProps - The properties of the gift to be created.
 * @param {File} giftProps.rewardPhoto - The photo of the reward.
 * @param {File} giftProps.rewardBanner - The banner of the reward.
 * @param {string} giftProps.rewardTitle - The title of the reward.
 * @param {string} giftProps.rewardUrl - The URL of the reward.
 * @param {string} giftProps.rewardDescription - The description of the reward.
 * @param {Object} giftProps.rewardType - The type of the reward.
 * @param {Object} giftProps.rewardContentType - The content type of the reward.
 * @param {string|File} giftProps.rewardContent - The content of the reward.
 * @param {string} giftProps.rewardDate - The date of the reward.
 * @param {boolean} giftProps.rewardUnique - Whether the reward is unique.
 * @param {string} giftProps.claimingTitle - The title of the claiming process.
 * @param {string} giftProps.claimingInstructions - The instructions for claiming the reward.
 * @returns {CreateGiftAction} The action object with the type and payload.
 */
export function createGiftAction(collectionId: number, {
  rewardPhoto,
  rewardBanner,
  rewardTitle,
  rewardUrl,
  rewardDescription,
  rewardType,
  rewardContentType,
  rewardContent,
  rewardDate,
  rewardUnique,
  claimingTitle,
  claimingInstructions
}: CreateGiftActionProps): CreateGiftAction {
  return {
    type: giftActionsTypes.CREATE_GIFT,
    payload: {
      collectionId,
      gift: {
        rewardPhoto,
        rewardBanner,
        rewardTitle,
        rewardUrl,
        rewardDescription,
        rewardType,
        rewardContentType,
        rewardContent,
        rewardDate,
        rewardUnique,
        claimingTitle,
        claimingInstructions
      }
    }
  };
}

/**
 * Creates an action to edit a gift.
 * @function
 * @param {number} giftId - The ID of the gift to be edited.
 * @param {EditGiftActionProps['data']} data - The data to be updated for the gift.
 * @returns {EditGiftAction} The action object with the type and payload.
 */
export function editGiftAction(giftId: number, data: EditGiftActionProps['data']): EditGiftAction {
  return {
    type: giftActionsTypes.EDIT_GIFT,
    payload: {
      giftId,
      data
    }
  };
}

/**
 * Creates an action to delete a gift.
 * @function
 * @param {number} giftId - The ID of the gift to be deleted.
 * @returns {DeleteGiftAction} The action object with the type and payload.
 */
export function deleteGiftAction(giftId: number): DeleteGiftAction {
  return {
    type: giftActionsTypes.DELETE_GIFT,
    payload: {
      giftId
    }
  };
}

/**
 * Creates an action to publish a gift.
 * @function
 * @param {number} giftId - The ID of the gift to be published.
 * @returns {PublishGiftAction} The action object with the type and payload.
 */
export function publishGiftAction(giftId: number): PublishGiftAction {
  return {
    type: giftActionsTypes.PUBLISH_GIFT,
    payload: {
      giftId
    }
  };
}
