import * as Yup from 'yup';

export const NewRewardStep1Validation = Yup.object().shape({
  rewardPhoto: Yup.mixed().required('This field is required'),
  rewardBanner: Yup.mixed().required('This field is required'),
  rewardTitle: Yup.string().required('The field is required'),
  rewardUrl: Yup.string()
    .url('Must be a valid URL')
    .test('is-https-url', 'Must be a valid HTTPS URL', value => {
      if (!value) {
        return true; // If rewardUrl is not provided, it's considered valid
      }
      return value.startsWith('https://');
    }),
  rewardDescription: Yup.string().required('The title is required'),
  rewardDate: Yup.date()
    .required('Date is required')
    .typeError('Invalid date format'),
  claimingTitle: Yup.string().required('The field is required'),
  claimingInstructions: Yup.string().required('The field is required')
});

export const NewRewardStep2Validation = Yup.object().shape({
  rewardUnique: Yup.boolean()
});
