// Dependencies
import React, {useRef, useCallback} from 'react';

// StyleSheet
import styles from './CreatorNFTCard.module.scss';

// Types
import {CreatorNFTCardProps} from './types';
import Typography from '@components/Typography';
import Button from '@components/Button';

import language_es from 'src/locales/es/components/cards/creatorNFTCard.json';
import language_en from 'src/locales/en/components/cards/creatorNFTCard.json';

function CreatorNFTCard({
  name,
  image,
  imageTypeId,
  description,
  copies,
  freeCopies,
  questCopies,
  canEdit,
  isMobile,
  onPressDelete,
  onPressEdit
}: CreatorNFTCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      void videoRef.current.play();
    }
  }, [videoRef]);

  const handlePauseVideo = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      void videoRef.current.pause();
    }
  }, [videoRef]);

  return (
    <div className={styles.card}>
      <div className={styles.title}>
        <Typography variant={'display'} size={'xs'} weight={'bold'}>{name}</Typography>
      </div>
      <div onMouseLeave={handlePauseVideo} onMouseEnter={handlePlayVideo} className={styles.image}>
        {imageTypeId === 2 ? (
          <video key={image} ref={videoRef} loop muted playsInline autoPlay={isMobile} className={styles.video}>
            <source src={image} />
          </video>
        ) : (
          <img src={image} className={styles.preview} />
        )}
      </div>
      <div className={styles.descriptionRow}>
        <Typography variant={'text'} size={'xs'} weight={'regular'} className={styles.label}>{description}</Typography>
      </div>
      <div className={styles.inline}>
        <div className={styles.row}>
          <Typography variant={'text'} size={'xs'} weight={'regular'} className={styles.label}>{language.totalCopies}</Typography>
          <Typography variant={'text'} size={'xs'} weight={'bold'} className={styles.value}>{copies}</Typography>
        </div>
        {freeCopies > 0 && (
          <div className={styles.row}>
            <Typography variant={'text'} size={'xs'} weight={'regular'} textAlign={'right'} className={styles.label}>{language.airdrop}</Typography>
            <Typography variant={'text'} size={'xs'} weight={'bold'} textAlign={'right'} className={styles.value}>{freeCopies}</Typography>
          </div>
        )}
        {questCopies > 0 && (
          <div className={styles.row}>
            <Typography variant={'text'} size={'xs'} weight={'regular'} textAlign={'right'} className={styles.label}>{language.questsCopies}</Typography>
            <Typography variant={'text'} size={'xs'} weight={'bold'} textAlign={'right'} className={styles.value}>{questCopies}</Typography>
          </div>
        )}
      </div>

      {canEdit && (<div className={styles.inline}>
        <Button size={'md'} onClick={onPressEdit}>{language.edit}</Button>
        <Button size={'md'} onClick={onPressDelete} variant={'solid'} color={'error'}>{language.delete}</Button>
      </div>)}
    </div>
  );
}

export default CreatorNFTCard;
