// Dependencies
import React, {useEffect, useMemo, useRef, useState} from 'react';
import classNames from 'clsx';
import {useSpring, animated} from 'react-spring';

// StyleSheet
import styles from './CreatorQuestCard.module.scss';

// Types
import {CreatorQuestCardProps} from './types';
import {QuestTypeEnum} from '@type/quest';

// Components
import QuillTextInput from '@components/QuillTextInput';
import Typography from '@components/Typography';
import Button from '@components/Button';
import IconButton from '@components/IconButton';

// Assets
import {FaCaretRight, FaDiscord, FaInstagram, FaTiktok, FaYoutube, FaTwitch} from 'react-icons/fa';
import XIcon from '@assets/icons/x-logo-tw.svg';

import language_es from 'src/locales/es/components/cards/creatorQuestCard.json';
import language_en from 'src/locales/en/components/cards/creatorQuestCard.json';

function CreatorQuestCard({
  quest,
  canEdit,
  isMobile,
  onPressDelete,
  onPressEdit
}: CreatorQuestCardProps): React.ReactElement {
  const language = navigator.language.startsWith('es') ? language_es : language_en;
  const [isQuestOpen, setQuestOpen] = useState<boolean>(false);
  const dropDownContainer = useRef<HTMLDivElement>();

  const [dropDownStyle, dropDownApi] = useSpring(() => ({
    height: 0,
    opacity: 0
  }));

  const renderIcon = useMemo(() => {
    if (!quest.questActionType) return null;
    switch (quest.questActionType.questType?.id) {
      case QuestTypeEnum.Twitter:
        return <XIcon />;

      case QuestTypeEnum.Instagram:
        return <FaInstagram />;

      case QuestTypeEnum.Discord:
        return <FaDiscord />;

      case QuestTypeEnum.TikTok:
        return <FaTiktok />;

      case QuestTypeEnum.YouTube:
        return <FaYoutube />;

      case QuestTypeEnum.Twitch:
        return <FaTwitch />;

      default:
        return null;
    }
  }, [quest]);

  useEffect(() => {
    if (dropDownContainer) {
      dropDownApi.start({
        opacity: isQuestOpen ? 1 : 0,
        height: isQuestOpen ? dropDownContainer.current?.clientHeight : 0
      });
    }
  }, [isQuestOpen, dropDownApi]);

  return (
    <div className={classNames([styles.card, {[styles.open]: isQuestOpen}])}>
      <div className={styles.header}>
        <IconButton
          className={styles.icon}
          onClick={() => setQuestOpen(!isQuestOpen)}
          size={'sm'}
          variant={'ghost'}
          icon={<FaCaretRight />}
        />
        <div className={styles.headTitle}>
          <div className={styles.title}>
            {renderIcon}
            <Typography variant={'text'} size={isMobile ? 'sm' : 'md'}>{quest.title}</Typography>
          </div>
          {canEdit && (
            <div className={styles.actions}>
              <Button size={'sm'} onClick={onPressEdit}>{language.edit}</Button>
              <Button size={'sm'} onClick={onPressDelete} variant={'solid'} color={'error'}>{language.delete}</Button>
            </div>
          )}
        </div>

      </div>
      <animated.div className={styles.body} style={dropDownStyle}>
        <div ref={ref => { if (ref) dropDownContainer.current = ref; }}>
          <QuillTextInput
            className={styles.description}
            theme='snow'
            value={quest.description}
            readOnly={true}
            modules={{toolbar: false}}
          />
        </div>
      </animated.div>
    </div>
  );
}

export default CreatorQuestCard;
