// Dependencies
import React from 'react';

// Components
import Button from '@components/Button';
import Typography from '@components/Typography';

// Styles
import styles from './GoogleConnectAction.module.scss';

// Types
import {GoogleConnectActionProps} from './types';

const GoogleConnectAction: React.FC<GoogleConnectActionProps> = ({collectionName, questId, disabled, isLoading}) => (
  <div className={styles.layout}>
    <Typography variant={'text'} textAlign={'center'} size={'md'} weight={'regular'}>🌐 Connect your YouTube account to GivitNFT to verify your participation easily and complete the quest. 🎥</Typography>

    <a href={`${process.env.REACT_APP_API_BASE_URL}/quests-google/auth/${collectionName}/${questId}?state=[${collectionName}, ${questId}]`}>
      <Button
        disabled={disabled}
        isLoading={isLoading}
        showTextOnLoading={true}>
        Link with Google and Claim as completed
      </Button>
    </a>
  </div>
);

export default GoogleConnectAction;
