// Dependencies
import React from 'react';

// Components
import Button from '@components/Button';
import Typography from '@components/Typography';

// Styles
import styles from './DiscordConnectAction.module.scss';

// Types
import {DiscordConnectActionProps} from './types';

const DiscordConnectAction: React.FC<DiscordConnectActionProps> = ({disabled, isLoading, onClick}) => (
  <div className={styles.layout}>
    <Typography variant={'text'} textAlign={'center'} size={'md'} weight={'regular'}>🌐 Connect your Discord account to GivitNFT to verify your participation easily and complete the quest. 🎥</Typography>
    <Button
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
      showTextOnLoading={true}>
        Link with Discord and Claim as completed
    </Button>
  </div>
);

export default DiscordConnectAction;
